import { get } from '@lumapps/constants';

const constants = get();
/**
 * Injects the current instance into the preloaded state, retrieving it
 * from the window variable.
 * @param initState - init response
 * @returns initState with instance updated
 */
const injectInstanceFromWindow = (initState: any = {}) => {
    Object.assign(initState, {
        instance: constants.instance || initState.instance,
    });

    return initState;
};

export { injectInstanceFromWindow };
