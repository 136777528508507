/* istanbul ignore file */
/** Still in development */
import { reducer as customerReducer } from '@lumapps/customer/ducks/slice';
import { reducer as features } from '@lumapps/features/ducks/slice';
import { reducer as instance } from '@lumapps/instance/reducer';
import { reducer as router } from '@lumapps/router/ducks/slice';
import { reducer as user } from '@lumapps/user/ducks/slice';

const defaultReducer = (state: any = {}) => state;

/**
 * HEAD'S UP!!
 * These should only be the login reducers!
 *
 * Also, the properties that are using the `defaultReducer` are the ones that still have a reducer
 * on the legacy side, and are functionalities that we need to still migrate. However, in order to
 * query their information, redux demands to have a reducer for each property. If we do not add a reducer
 * for `customer` for example, we cannot do `state.customer` since redux will not save that information from
 * the initial state.
 */
export default {
    customer: customerReducer,
    features,
    instance,
    user,
    router,
    locale: defaultReducer,
    styles: defaultReducer,
};
