/* eslint-disable react/self-closing-comp */
import React from 'react';

import { useClassnames, margin, classnames } from '@lumapps/classnames';
import { GoBackButton, GoBackButtonProps } from '@lumapps/lumx-buttons/components/GoBackButton';
import { ThumbnailWithIcon, ThumbnailWithIconProps } from '@lumapps/lumx-images/components/ThumbnailWithIcon';
import {
    Alignment,
    FlexBox,
    FlexBoxProps,
    HeadingProps,
    Orientation,
    Size,
    TextProps,
    Typography,
    TypographyInterface,
} from '@lumapps/lumx/react';

import { HeaderParentTitle, HeaderParentTitleProps } from './HeaderParentTitle';
import { HeaderSubtitle } from './HeaderSubtitle';
import { HeaderTitle } from './HeaderTitle';

import './index.scss';

export interface HeaderLabelProps {
    /** The title to show */
    title?: string | React.ReactNode;
    /** Any element to be display before the title (on the same line) */
    beforeTitle?: string | React.ReactNode;
    /** Any element to be display after the title (on the same line) */
    afterTitle?: string | React.ReactNode;
    /** The subtitle to show */
    subtitle?: string | React.ReactNode;
    /** The parent element to show */
    parentTitle?: string | React.ReactNode | null;
    /** The component shown beside the title */
    children?: React.ReactNode;
    /** id for the header title */
    headerTitleId: string;
    /** id for the header subtitle */
    headerSubtitleId?: string;
    /** custom title props */
    titleProps?: Partial<HeadingProps>;
    /** custom subtitle props */
    subtitleProps?: Partial<TextProps>;
    parentTitleProps?: Partial<HeaderParentTitleProps>;
    /** image or icon to be displayed on the left hand side of the header */
    thumbnail?: ThumbnailWithIconProps;
    /** additional props for the header flexbox wrapper */
    wrapperProps?: Partial<FlexBoxProps>;
    /** custom class name */
    className?: string;
    /** props for go back button */
    goBackButtonProps?: GoBackButtonProps;
    /** whether the header is loading or not */
    isLoading?: boolean;
}

const CLASSNAME = 'lumx-header-label';

/**
 * Label that can be displayed as a header on any page
 *
 * @family Texts
 * @param HeaderLabelProps
 * @returns HeaderLabel
 */
export const HeaderLabel: React.FC<HeaderLabelProps> = ({
    title,
    beforeTitle,
    afterTitle,
    subtitle,
    parentTitle,
    children,
    headerTitleId,
    headerSubtitleId,
    titleProps,
    subtitleProps,
    parentTitleProps,
    thumbnail,
    wrapperProps = {},
    goBackButtonProps,
    isLoading = false,
    className,
}) => {
    const { block, element } = useClassnames(CLASSNAME);

    const typography = subtitle ? Typography.title : Typography.headline;

    const titleDisplay = (
        <HeaderTitle
            title={title}
            subtitle={subtitle}
            headerTitleId={headerTitleId}
            typography={typography as TypographyInterface}
            CLASSNAME={CLASSNAME}
            titleProps={titleProps}
            isLoading={isLoading}
        />
    );

    const fullTitle =
        afterTitle || beforeTitle ? (
            <FlexBox orientation={Orientation.horizontal} hAlign={Alignment.center} gap={Size.regular}>
                {beforeTitle}
                {titleDisplay}
                {afterTitle}
            </FlexBox>
        ) : (
            titleDisplay
        );

    return (
        <div className={block([className])}>
            <FlexBox
                orientation={Orientation.horizontal}
                {...wrapperProps}
                className={element('title-wrapper', [margin('right', 'huge'), wrapperProps?.className])}
                hAlign={Alignment.center}
            >
                {goBackButtonProps ? (
                    <GoBackButton {...goBackButtonProps} className={margin('right')} isDisabled={isLoading} />
                ) : null}

                {thumbnail ? (
                    <ThumbnailWithIcon
                        {...thumbnail}
                        className={classnames(thumbnail.className, margin('right', 'huge'))}
                    />
                ) : null}

                {parentTitle && (
                    <HeaderParentTitle
                        CLASSNAME={CLASSNAME}
                        typography={typography as TypographyInterface}
                        isLoading={isLoading}
                        parentTitle={parentTitle}
                        {...parentTitleProps}
                    />
                )}

                {subtitle ? (
                    <HeaderSubtitle
                        CLASSNAME={CLASSNAME}
                        fullTitle={fullTitle}
                        headerSubtitleId={headerSubtitleId}
                        isLoading={isLoading}
                        subtitle={subtitle}
                        subtitleProps={subtitleProps}
                    />
                ) : (
                    fullTitle
                )}
            </FlexBox>

            {children}
        </div>
    );
};
