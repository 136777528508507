import React from 'react';

import { mdiHome } from '@lumapps/lumx/icons';
import { ButtonProps, ColorVariant, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { ERROR_PAGE } from '../../constants';
import { BaseState } from '../BaseState';

import './index.scss';

export interface ErrorPageStateProps {
    /** error code */
    code: number;
    /** whether an icon should be displayed */
    shouldShowIcon?: boolean;
    /** whether an action (normally a retry) should be displayed */
    shouldShowAction?: boolean;
    /** icon to display */
    icon?: string;
    /** additional button props */
    buttonProps?: Partial<ButtonProps>;
    /** title to be displayed */
    title?: string;
    /** description to be displayed below the title */
    description?: string;
    theme?: Theme;
}

const CLASSNAME = 'error-page';

/**
 * Page that the user will be redirected when there is an unexpected error in a navigation.
 *
 * @family States
 * @param ErrorPageStateProps
 * @returns ErrorPageState
 */
export const ErrorPageState: React.FC<ErrorPageStateProps> = ({
    code,
    shouldShowIcon,
    shouldShowAction,
    buttonProps = {},
    theme,
    icon = ERROR_PAGE[code]?.icon as string,
    title = ERROR_PAGE[code]?.title,
    description = ERROR_PAGE[code]?.description,
}) => {
    const { translateKey } = useTranslate();
    const pageTitle = translateKey(title as string);
    const message = translateKey(description as string);

    return (
        <BaseState
            className={CLASSNAME}
            theme={theme}
            iconProps={shouldShowIcon ? { icon } : undefined}
            buttonProps={shouldShowAction ? { leftIcon: mdiHome, ...buttonProps } : undefined}
            title={pageTitle}
            titleAs="h1"
            message={message}
            messageProps={{ colorVariant: ColorVariant.L2, whiteSpace: 'pre-wrap' }}
        />
    );
};
