import { Tag } from '@lumapps/tags/types';
import { TranslateObject } from '@lumapps/translations';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

/**
 * List of all legacy content types.
 *
 * This list was previously injected in the HTML by the backend, but we moved it here since it is not a dynamic list.
 * @see https://github.com/lumapps/lumapps-back/blob/master/app/server/content/__init__.py
 */
export const ContentTypes = {
    COMMUNITY: 'community',
    CUSTOM: 'custom',
    CUSTOM_LIST: 'custom_list',
    DIRECTORY: 'directory',
    IMAGE_GALLERY: 'image_gallery',
    MENU: 'menu',
    NEWS: 'news',
    NEWS_LIST: 'news_list',
    PAGE: 'page',
    POST: 'post',
    TUTORIAL: 'tutorial',
    USER_DIRECTORY: 'user_directory',
} as const;

export type ContentType = ObjectValues<typeof ContentTypes>;

export const CCT_SCOPE = 'custom-content-types';

export const BASIC_CONTENT_TYPES: string[] = Object.values(ContentTypes);

export const NON_DELETABLE_CONTENT_TYPES: string[] = [ContentTypes.PAGE, ContentTypes.NEWS];

/**
 * https://api.lumapps.com/docs/output/_schemas/customcontenttypetag.html
 */
export type CustomContentTypeTag = Tag;

/**
 * https://api.lumapps.com/docs/output/_schemas/customcontenttype.html
 */
export type CustomContentType = {
    id: string;
    uid: string;
    name: TranslateObject;
    icon: string;
    tags: CustomContentTypeTag[];
    instance: string;
    customer: string;
    heritable?: boolean;
    heritableLocked?: boolean;
    createFromScratchLocked?: boolean;
    isWorkflowEnabled?: boolean;
    isEndDateMandatory?: boolean;
    notifyContributors?: boolean;
    endDateDelta?: string;
    functionalInnerId?: string;
    /** Information regarding the source site of the CTT */
    instanceDetails?: {
        id: string;
        name: string;
        slug: string;
        title: TranslateObject;
    };
};

/**
 * Type used when creating a new Content Type, with optional id and uid
 */
export type CustomContentTypeCreationPayload = Omit<CustomContentType, 'id' | 'uid'> & {
    id?: string;
    uid?: string;
};

export type ContentTypeIdParameter = Record<'contentTypeId', CustomContentType['id']>;
