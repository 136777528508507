import React from 'react';

import BaseApi, { API_VERSION } from '@lumapps/base-api';
import { cache, CACHE_TYPE } from '@lumapps/cache';
import { get } from '@lumapps/constants';
import { track } from '@lumapps/metric/hooks/usePendo/track';

import { Subscriber, CustomComponent, Targets, PLACEMENT, CustomizationApiProps, Session, Events } from '../types';
import { components, constants } from './components';
import store from './store';
import { useSession } from './useSession';
import { isCustomizationsApiUsed } from './utils';

const Config = get();

const customizationsApi = new BaseApi({ path: '', baseURL: Config.applicationHost });
const customizationsApiV2 = new BaseApi({ path: '', version: API_VERSION.v2 });

/**
 * List of props that will be exposed for the customizations API.
 */
const customizationsApiProps: CustomizationApiProps = {
    api: customizationsApi,
    apiV2: customizationsApiV2,
    render: (customComponent: CustomComponent) => {
        store.setCustomComponent(customComponent);
    },
    onNavigation: (callback: Subscriber['callback']) => {
        store.subscribeTo({ callback, event: Events.NAVIGATION });
    },
    onWidgetRendered: (callback: Subscriber['callback']) => {
        store.subscribeTo({ callback, event: Events.WIDGET_RENDERED });
    },
    on: (eventType: Events, callback: Subscriber['callback']) => {
        store.subscribeTo({ event: eventType, callback });
    },
    pushEvent: (eventType: string, props) => {
        store.notifySubscribers(props, eventType as Events);
    },
    state: {
        get: (key) => {
            return cache.retrieve(`custo-api-${key}`, CACHE_TYPE.MEMORY);
        },
        set: (key, value) => {
            cache.store(`custo-api-${key}`, value, CACHE_TYPE.MEMORY);
        },
    },
    getLatestEvents: (eventType) => {
        return store.getLatestEvents(eventType);
    },
    components,
    targets: Targets,
    constants,
    placement: PLACEMENT,
    events: Events,
};

const setupSubscriber = (subscriber: Subscriber, session: any) => {
    try {
        subscriber.callback({
            ...customizationsApiProps,
            session,
        });

        /**
         * Subscribers can have several configurations that can change when they are executed and how.
         */
        if (subscriber.configuration) {
            /**
             * If the subscriber wants to re render the customization when the application navigates, the shouldRenderOnNavigation
             * will be true. In that case, we add the callback to the navigation subscriptions.
             */
            if (subscriber.configuration.shouldRenderOnNavigation) {
                store.subscribeTo({
                    callback: (props: any) => {
                        subscriber.callback({
                            ...customizationsApiProps,
                            session,
                            ...props,
                        });
                    },
                    event: Events.NAVIGATION,
                });
            }
        }
    } catch (exc) {
        // eslint-disable-next-line no-console
        console.error('Error while executing customization:', exc);
    }
};

const setupStore = (session: Session) => {
    /**
     * If the store was already initialized, we avoid this step. There is also a small
     * caveat when adding the API to the legacy side, the redux store might not be initialized
     * when this hook is executed, so we double check that there is a user connected before
     * executing our customizations
     */
    if (!store.wasStoreInitialized() && session.isConnected) {
        /**
         * If there are any customizations in place, meaning that they were added on the head of the page,
         * we execute those callbacks directly.
         */
        if (isCustomizationsApiUsed()) {
            window.lumapps.subscribers.forEach((subscriber: Subscriber) => {
                setupSubscriber(subscriber, session);
            });
        }

        /**
         * We also override the exposed API since before the application has finished rendering, the code
         * only retrieves customizations but does not execute them. Instead, we override that object and
         * expose the real API.
         */
        Object.assign(window.lumapps, {
            customize: (callback: Subscriber['callback'], configuration?: Subscriber['configuration']) => {
                setupSubscriber(
                    {
                        callback,
                        configuration,
                    },
                    session,
                );
            },
            disable: (target: Targets | Targets[]) => {
                const targets = Array.isArray(target) ? target : [target];

                targets.forEach((t) => {
                    track({
                        name: 'Customizations | Disable',
                        props: {
                            target: t,
                        },
                    });

                    store.disableComponent(t);
                });
            },
        });

        store.setStoreAsInitialized();
    }
};

/**
 * Hook that will expose the customizations API and hook the different subscribers
 * to the different customizable components.
 */
const useApi = () => {
    const session = useSession();

    React.useEffect(() => {
        setupStore(session);
    }, [session]);
};

export { useApi, setupSubscriber, setupStore };
