import React from 'react';

import { LoginPage } from '@lumapps/login/components/LoginPage';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SIGN_IN } from '../../keys';
import { SignInForm } from '../SignInForm';

export const SignInStep: React.FC<StepProps> = ({ backButton, goToNextStep }) => {
    const { translateKey } = useTranslate();

    return (
        <LoginPage
            title={translateKey(GLOBAL.SIGN_IN)}
            hideTitle
            subtitle={translateKey(SIGN_IN.ENTER_CREDENTIALS)}
            before={backButton}
            displayPoweredBy
        >
            <SignInForm goToNextStep={goToNextStep} />
        </LoginPage>
    );
};
