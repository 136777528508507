import { logException } from '@lumapps/utils/log/logException';
import { RecursivePartial } from '@lumapps/utils/types/RecursivePartial';

import { EXTRACT_STREAMING_CHUNK_REGEX } from '../constants';

/**
 * The expected data response sorted by event name.
 * ex:
 * {
 *      event1: { id: string, date: string },
 *      event2: { id: string, name: string }
 * }
 */
export type DataByEventType = { [eventName: string]: unknown };

/** Format returned for each chunk */
export interface StreamChunk<T = unknown> {
    /** The raw and untouched chunk string sent by the backend */
    rawChunk: string;
    /** The name linked of the event */
    event: string;
    /** The data linked to the event */
    data: RecursivePartial<T>;
}

/**
 * Format returned for each events.
 */
export type StreamingResponse<T = DataByEventType> = {
    [key in keyof T]?: {
        /** The last chunk received for this event */
        lastChunk: T[keyof T];
        /** List of all chunks received fot this event. */
        chunks: Array<StreamChunk<T[keyof T]>>;
    };
};

/**
 * Generator function to stream responses from fetch calls.
 */
async function* readStream(reader: ReadableStreamDefaultReader<string>) {
    // Attach Reader
    while (true) {
        // wait for next encoded chunk
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        // check if stream is done
        if (done) {
            break;
        }
        // Decodes data chunk and yields it
        yield value;
    }
}

/**
 * Manages a readable stream type response to intercept each chunk, format them, and return them as they are received.
 */
export async function* formatStreamResponse<StreamEvents = DataByEventType>(
    /**
     * The readable stream to format
     */
    stream: ReadableStream,
): AsyncGenerator<StreamingResponse<StreamEvents>> {
    /** Decode the stream as each chunk are encoded in binary by default  */
    const reader = stream.pipeThrough(new TextDecoderStream()).getReader();

    /** Array to store each chunk */
    let chunksData: StreamingResponse<StreamEvents> = {};

    for await (const chunk of readStream(reader)) {
        try {
            /** Extract the event name and data from each chunks */
            const chunkData = chunk.matchAll(EXTRACT_STREAMING_CHUNK_REGEX);
            /** Convert it to an array */
            const chunkDataToArray = Array.from(chunkData);
            /**
             * Reduce the array of chunks to an object that will group each chunk by event name.
             * If no even name is given with a chunk, it will go to a `default` key.
             */
            const results = chunkDataToArray.reduce((acc, [rawChunk, event, data]) => {
                const eventKey = event as keyof StreamEvents;
                /** All previous chunks that have the same event name */
                const previousValues = acc[eventKey]?.chunks || [];
                /**
                 * The current chunk's data
                 * As we are parsing json, we don't have a choice to cast the correct type
                 */
                const lastChunk = JSON.parse(data) as RecursivePartial<StreamEvents[typeof eventKey]>;

                return {
                    ...acc,
                    [event || 'default']: {
                        lastChunk,
                        chunks: [
                            ...previousValues,
                            {
                                rawChunk,
                                event,
                                data: lastChunk,
                            },
                        ],
                    },
                };
            }, chunksData);

            /**
             * Merge the new chunks object with the previous to keep track of all events.
             */
            chunksData = { ...chunksData, ...results };

            /** Submit the chunk to the onChunkDownload callback */
            yield chunksData;
        } catch (error) {
            logException(error);

            return chunksData;
        }
    }

    /** Once the stream ends, return the complete data as response */
    return chunksData;
}
