// eslint-disable-next-line lumapps/do-not-import-react-query
import type { UseMutationResult, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

/**
 * Get a BaseLoadingStatus value from an useQuery result
 * @deprecated your `useQuery` already returns a `baseLoadingStatus`;
 */
export const getStatusFromQuery = (query: Pick<UseQueryResult, 'status'>) => {
    switch (query.status) {
        case 'loading':
            return BaseLoadingStatus.loading;
        case 'error':
            return BaseLoadingStatus.error;
        case 'success':
        default:
            return BaseLoadingStatus.idle;
    }
};

/**
 * Get a BaseLoadingStatus value from an useInfiniteQuery result
 * @deprecated your `useInfiniteQuery` already returns a `baseLoadingStatus`;
 */
export const getStatusFromInfiniteQuery = (query: Pick<UseInfiniteQueryResult, 'status' | 'isFetchingNextPage'>) => {
    if (query.isFetchingNextPage) {
        return BaseLoadingStatus.loadingMore;
    }

    switch (query.status) {
        case 'loading':
            return BaseLoadingStatus.loading;
        case 'error':
            return BaseLoadingStatus.error;
        case 'success':
        default:
            return BaseLoadingStatus.idle;
    }
};

/**
 * Get a BaseLoadingStatus value from an useMutation result
 * @deprecated your `useMutation` already returns a `baseLoadingStatus`;
 */
export const getStatusFromMutationQuery = (query: Pick<UseMutationResult, 'status'>): BaseLoadingStatus => {
    switch (query.status) {
        case 'success':
        case 'idle':
            return BaseLoadingStatus.idle;
        case 'loading':
            return BaseLoadingStatus.loading;
        default:
            return BaseLoadingStatus.error;
    }
};
