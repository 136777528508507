import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Typography, SkeletonRectangle, SkeletonRectangleVariant, Size } from '@lumapps/lumx/react';

export const LoadingContent: React.FC<{ className: string }> = ({ className }) => {
    const { element } = useClassnames(className);

    return (
        <>
            <SkeletonRectangle
                className={element('skeleton-button')}
                variant={SkeletonRectangleVariant.squared}
                width={Size.xxl}
                height={Size.m}
            />
            <SkeletonRectangle
                className={element('skeleton-button')}
                variant={SkeletonRectangleVariant.squared}
                width={Size.xxl}
                height={Size.m}
            />
            <SkeletonRectangle
                className={element('skeleton-button')}
                variant={SkeletonRectangleVariant.squared}
                width={Size.xxl}
                height={Size.m}
            />

            <SkeletonTypography className={element('skeleton-subtitle')} width="200px" typography={Typography.body1} />

            <SkeletonTypography className={element('skeleton-subtitle')} width="120px" typography={Typography.body1} />
        </>
    );
};
