/* istanbul ignore file */
import { get } from '@lumapps/constants';
import { connect } from '@lumapps/redux/react';

import { ProvidersStep } from './ProvidersStep';

const Config = get();

const mapStateToProps = () => {
    return {
        // rendered by Genesis in the Login application template
        loginProviders: Config.idpUrls,
    };
};

const ConnectedProvidersStep = connect(mapStateToProps)(ProvidersStep);

export { ConnectedProvidersStep as ProvidersStep };
