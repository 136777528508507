import React from 'react';

import { useInstanceLogo } from '@lumapps/instance/hooks/useInstanceLogo';
import { useQueryParams } from '@lumapps/router';
import { usePageTitle } from '@lumapps/utils/hooks/usePageTitle';

import { LoginPage } from './LoginPage';
import { LoginPageProps } from './LoginPageProps';

const ConnectedLoginPage: React.FC<Omit<LoginPageProps, 'logo'>> = (props) => {
    const { title } = props;
    const logo = useInstanceLogo();
    usePageTitle(title);
    const { correlationId } = useQueryParams();

    return <LoginPage {...props} logo={logo} correlationId={correlationId} />;
};

export { ConnectedLoginPage as LoginPage };
