import googleLogo from '@lumapps/assets/brands/google.svg';
import lumappsLogo from '@lumapps/assets/brands/lumapps.svg';
import microsoftLogo from '@lumapps/assets/brands/microsoft.svg';
import oktaLogo from '@lumapps/assets/brands/okta.svg';
import { IDENTITY_PROVIDER_TYPES } from '@lumapps/reseller-console/types';

import { LoginProviderButtonProps } from './types';

export const LOGIN_METHODS = {
    EMAIL: 'EMAIL',
    GOOGLE: 'GOOGLE',
    MICROSOFT: 'MICROSOFT',
    OKTA: 'OKTA',
    SAML: 'SAML',
};

export const LOGIN_PROVIDER_CONFIGURATION: Record<any, Partial<LoginProviderButtonProps>> = {
    [IDENTITY_PROVIDER_TYPES.OKTA]: {
        leftImage: oktaLogo,
        loginMethod: LOGIN_METHODS.OKTA,
    },
    [IDENTITY_PROVIDER_TYPES.GOOGLE]: {
        leftImage: googleLogo,
        loginMethod: LOGIN_METHODS.GOOGLE,
    },
    [IDENTITY_PROVIDER_TYPES.MICROSOFT]: {
        leftImage: microsoftLogo,
        loginMethod: LOGIN_METHODS.MICROSOFT,
    },
    [IDENTITY_PROVIDER_TYPES.EMAIL]: {
        leftImage: lumappsLogo,
        loginMethod: LOGIN_METHODS.EMAIL,
    },
    [IDENTITY_PROVIDER_TYPES.SAML]: {
        leftImage: lumappsLogo,
        loginMethod: LOGIN_METHODS.SAML,
    },
    [IDENTITY_PROVIDER_TYPES.SAML_V2]: {
        leftImage: lumappsLogo,
        loginMethod: LOGIN_METHODS.SAML,
    },
};
