/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';

import {
    getAlternativeLanguages,
    createXMLHttpRequestToRetrieveLanguage,
    getDefaultLanguage,
} from '@lumapps/languages/utils';
import { useParseQueryParams } from '@lumapps/router/hooks/useQueryParams';
import { TranslationConfig } from '@lumapps/translations';

import { didRequestFail, didRequestSucceed, didRequestFinish } from './utils';

declare const window: any;

/**
 * This hook adds the different listeners in order to retrieve the information from the
 * translations request. If the request has finished, we trigger the initial state loaded, and if it has not finished,
 * we add a listener to the request and trigger the onLoad when it is the right time.
 *
 * In case there is something wrong with the language bundle, we still need to render something on the page. So if something
 * fails, we will retrieve an alternative language that comes from the `window.USER_ALTERNATIVE_LANGS` variable and retrieve that language instead.
 */
const useRetrieveTranslations = () => {
    const [translationState, setTranslationsState] = useState<TranslationConfig | undefined>();
    const queryParams = useParseQueryParams({ rawUrlParams: window.location.search });

    let retry = 0;

    /**
     * Callback when a language retrieval fails.
     *
     * Alternative languages are based on users' alternative language and sites' languages.
     * Get the next alternative for the user until it works. Fallback to the default language in last resort.
     */
    const onLanguageFail = () => {
        const userAlternativeLanguages = getAlternativeLanguages();
        const userAlternativeLang = userAlternativeLanguages[retry]
            ? userAlternativeLanguages[retry]
            : getDefaultLanguage();
        retry += 1;
        window.userLangRequest = createXMLHttpRequestToRetrieveLanguage(
            userAlternativeLang,
            onTranslationsLoaded,
            () => {
                /**
                 * If the language retrieval fails for a second time, we want to stop trying to retrieve a language
                 * and just go ahead and load the default language.
                 */
                window.userLangRequest = createXMLHttpRequestToRetrieveLanguage(
                    getDefaultLanguage(),
                    onTranslationsLoaded,
                    () => {
                        return setTranslationsState({
                            error: true,
                            loaded: false,
                            translations: {},
                        });
                    },
                );
            },
        );
    };

    /**
     * Callback to be executed once the language file is retrieved. Since we are parsing a JSON, we add a try/catch in case
     * something goes wrong.
     */
    const onTranslationsLoaded = () => {
        if (didRequestSucceed(window.userLangRequest)) {
            const { responseText: responseTextLang } = window.userLangRequest;

            try {
                setTranslationsState({
                    translations: {
                        [`${window.USER_LANG}`]: { ...JSON.parse(responseTextLang) },
                    },
                    preferredLanguage: window.USER_LANG,
                });
            } catch (exception) {
                onLanguageFail();
            }
        } else if (didRequestFail(window.userLangRequest)) {
            onLanguageFail();
        }
    };

    useEffect(() => {
        /**
         * If the debugLang query param is present, we just load nothing as translations, so that
         * the text printed will be the translation key.
         */
        if (queryParams && queryParams.debugLang) {
            setTranslationsState({
                translations: {
                    [`${window.USER_LANG}`]: {},
                },
                preferredLanguage: window.USER_LANG,
                loaded: true,
            });
        } else if (didRequestFinish(window.userLangRequest)) {
            onTranslationsLoaded();
        } else {
            window.userLangRequest.addEventListener('load', onTranslationsLoaded);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return translationState || null;
};

export { useRetrieveTranslations };
