import React from 'react';

interface UseMediaQueryOptions {
    /** The media query string (e.g., '(min-width: 768px)') */
    query: string;
}

// Safely access `matchMedia` API (might not exist in current JS env)
const matchMedia = typeof window !== 'undefined' ? window.matchMedia : undefined;

/**
 * Observe a CSS media query and return whether it matches or not.
 * (Lightweight version of `react-responsive` `useMediaQuery()`.)
 *
 * @param options - The options for the media query.
 * @returns `true` if the media query matches, `false` otherwise.
 */
export function useMediaQuery(options: UseMediaQueryOptions): boolean {
    const [matches, setMatches] = React.useState<boolean>(
        // Initial state (could be avoided but it's better to have a value in the first render not waiting for the useEffect)
        () => matchMedia?.(options.query).matches || false,
    );

    React.useEffect(() => {
        const mediaQuery = matchMedia?.(options.query);
        if (!mediaQuery) {
            // Some JS env might not have the `matchMedia` API
            return undefined;
        }
        setMatches(mediaQuery.matches);

        // Update matches when media query match changes
        const updateMatches = (evt: MediaQueryListEvent) => {
            setMatches(evt.matches);
        };
        mediaQuery.addEventListener('change', updateMatches);

        // Remove listener when query changes
        return () => {
            mediaQuery.removeEventListener('change', updateMatches);
        };
    }, [options.query]);

    return matches;
}
