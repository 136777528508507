export enum SIGN_IN_CORE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184119615
     */
    POWERED_BY = 'LOGIN_POWERED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24477888
     */
    LOGIN_GENERIC_SIGN_IN = 'LOGIN.GENERIC_SIGN_IN',
}
