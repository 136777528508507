/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { ServerListResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { get } from '@lumapps/constants';

const Config = get();

declare const window: any;

export interface Icon {
    path: string;
    name: string;
}

/**
 * CDN URLs look like this https://dev.cdn.lumapps.com/4ad2b44cc5-chore-apps-remote-icon/ so in order to retrieve
 * the base URL of our CDN, we need to remove the last slash (`H.slice(0, -1)`) and retrieve the string from 0
 * to the last position of `/` (.substring(0, window.PUBLIC_PATH.slice(0, -1).lastIndexOf('/') + 1)).
 */
const baseCDNPath = window.PUBLIC_PATH
    ? window.PUBLIC_PATH.substring(0, window.PUBLIC_PATH.slice(0, -1).lastIndexOf('/') + 1)
    : '';
const baseURL = window.PUBLIC_PATH && window.PUBLIC_PATH.indexOf('localhost') >= 0 ? window.PUBLIC_PATH : baseCDNPath;

export const iconApi = new BaseApi({ baseURL });

export interface GetIconParams {
    /** Icon id, it should be the same that is used when importing an icon from @lumapps/lumx/react, so mdiAccount for example */
    icon: string;
}

const paths = {
    getIcon: (params: GetIconParams) => `${Config.iconsCDNPath}/${params.icon}.json`,
    getAllIcons: () => Config.iconPickerListCDNPath,
};

/**
 * Retrieves the svg path for a given icon.
 *
 * Returns a promise.
 */
const getIcon = async (params: GetIconParams, signal?: AbortSignal) => {
    const { data } = await iconApi.get<{ path: string }>(
        paths.getIcon(params),
        { signal, withCredentials: false },
        false,
    );
    return data;
};

const getAllIcons = (signal?: AbortSignal) => {
    return iconApi.getCacheFirst<ServerListResponse<Icon>>(
        paths.getAllIcons(),
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            withCredentials: false,
            signal,
        },
        false,
    );
};
const PATH = 'icons';
const iconQueryKeys = {
    all: PATH,
    getIcon: (icon?: string) => [PATH, icon] as const,
};

export { getIcon, getAllIcons, iconQueryKeys };
