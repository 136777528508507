/* istanbul ignore file */
/** tested with integration tests */
import { useStore } from '@lumapps/redux/react';

import { contentSelector } from '../ducks/selectors';
import { useInjectTapper } from './useInjectTapper';

const useContentTapper = () => {
    const store = useStore();

    useInjectTapper({
        getCurrentContent: () => {
            const state: any = store?.getState();

            return contentSelector(state);
        },
    });
};

export { useContentTapper };
