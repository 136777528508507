import React from 'react';

// eslint-disable-next-line lumapps/do-not-import-react-query
import {
    useMutation,
    type UseMutationOptions as BaseUseMutationOptions,
    type UseMutationResult as BaseUseMutationResult,
} from '@tanstack/react-query';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { BaseApiError } from '../types';
import { getStatusFromMutationQuery } from './utils';

/**
 * Abstract the `UseMutationOptions` type in order to omit `mutationKey` and `mutationFn` keys because
 * they should never be overriden
 *
 * 💡 This type can be used to type the `options` object of your custom react-query hook
 * ℹ️ This export takes precedence over the `export * from '@tanstack/react-query'` statement below.
 */
export type UseMutationOptions<TData = unknown, TError = BaseApiError, TVariables = void, TContext = unknown> = Omit<
    BaseUseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
>;

export type UseMutationResult<
    TData = unknown,
    TError = BaseApiError,
    TVariables = void,
    TContext = unknown,
> = BaseUseMutationResult<TData, TError, TVariables, TContext> & {
    /** base loading status for the current query */
    baseLoadingStatus: BaseLoadingStatus;
};

/**
 * React Query Use Mutation, used when doing API modifications to an API service.
 * Please take a look at the examples on how to implement it.
 *
 * @example
 * import { useMutation, useQueryClient } from '@lumapps/base-api/react-query';
 *
 * import { deleteTags, tagsQueryKeys } from '../../api';
 * import type { Tag } from '../../types';
 *
 * export const useDeleteTags = () => {
 *     const queryClient = useQueryClient();
 *
 *     return useMutation({
 *         mutationFn: (variables: Tag[]) => deleteTags(variables),
 *         onSuccess: async () => {
 *             await queryClient.invalidateQueries({ queryKey: tagsQueryKeys.all() });
 *         },
 *     });
 * };
 * @param options
 * @returns UseMutationResult
 */
export function useCustomMutation<TData = unknown, TError = BaseApiError, TVariables = void, TContext = unknown>(
    options: BaseUseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> {
    // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
    const query = useMutation(options);

    return React.useMemo(() => {
        // eslint-disable-next-line deprecation/deprecation
        const baseLoadingStatus = getStatusFromMutationQuery(query);

        return {
            ...query,
            baseLoadingStatus,
        };
    }, [query]);
}
