import find from 'lodash/find';
import omit from 'lodash/omit';
import values from 'lodash/values';

import { get } from '@lumapps/constants';
import { CONTENT_LIST_LINK_OPEN_NEW_TAB } from '@lumapps/customer/constants';
import { getCustomerProperties } from '@lumapps/customer/ducks/selectors';
import { CustomerProperties } from '@lumapps/customer/types';
import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

import { Instance } from '../types';
import { getInstanceLogo as getInstanceLogoUtil } from '../utils/getCurrentInstanceLogoUrl';

const internalInstanceSelector = (state: BaseStore) => state.instance || {};

/**
 * Retrieves the instance object
 * @param  {Object} state The redux state.
 * @return {Object} Instance state
 */
const instanceSelector = createSelector(internalInstanceSelector, (instance) => instance);

/**
 * Retrieves the id of the current instance
 * @param  {Object} state The redux state.
 * @return {string} Instance id
 */
const instanceIdSelector = createSelector(internalInstanceSelector, (instance) => instance.uid || instance.id);

const { defaultLogo, publicPath, defaultLanguage, defaultContributionLanguages } = get();

/**
 * Retrieves ids for the different sites associated to the current instance
 * @param  {Object} state The redux state.
 * @return {Array} Site ids
 */
const getSiteIds = createSelector(instanceSelector, instanceIdSelector, (instance, instanceId) => {
    const siteIds = [instanceId];

    if (instance.parent) {
        siteIds.push(instance.parent);
    }

    return siteIds;
});

/**
 * Retrieves the information for the current instance
 * @param  {Object} state The redux state.
 * @return {Object} Instance
 */
const getCurrentInstance = createSelector(instanceSelector, instanceIdSelector, (instance, instanceId): Instance => {
    const { entities } = instance;

    if (entities && entities[instanceId]) {
        return entities[instanceId];
    }

    return instance && instance.id
        ? instance
        : {
              name: '',
              id: '',
              uid: '',
              logo: [],
              slug: '',
              publicContentAuthorized: false,
              parent: undefined,
              homePage: undefined,
              langs: [],
              defaultLang: defaultLanguage,
              properties: {},
              subtitle: {},
              title: {},
              isDefaultInstance: false,
              style: '',
              defaultUserDirectory: '',
              googleAnalytics: '',
              googleTagManager: '',
              isInFavoriteFeedKeys: [],
          };
});

const getCurrentInstanceName = createSelector(getCurrentInstance, (instance) => instance.name);

const doesCurrentInstanceAllowPublicContent = createSelector(
    getCurrentInstance,
    (instance) => instance.publicContentAuthorized,
);

/** retrieves the raw instance logo */
const getInstanceLogo = createSelector(instanceSelector, (instance) => {
    return getInstanceLogoUtil(instance);
});

const getCurrentInstanceParentId = createSelector(getCurrentInstance, (instance) => {
    return instance.parent;
});

const getHomePageId = createSelector(getCurrentInstance, (currentInstance) => currentInstance.homePage);

/** Retrieves the logo with the fallback in case the logo is not defined */
const getInstanceLogoProps = createSelector(getInstanceLogo, getHomePageId, (logo, homePageId) => {
    return {
        fallbackHomeImageSrc: `${publicPath}${defaultLogo}`,
        homeImageSrc: logo,
        homePageId,
    };
});

/**
 * Retrieves the languages for the current instance
 * @param  {Object} state The redux state.
 * @return {Array} list of languages for the current instance
 */
const getInstanceLanguages = createSelector(getCurrentInstance, (instance) => {
    return instance.langs;
});

const getDefaultInstanceLanguage = createSelector(getCurrentInstance, (instance) => {
    return instance.defaultLang;
});

/**
 * Get the index of instance.
 *
 * @param  {Object} state The redux state.
 * @return Index of instance.
 */
const getInstanceById = createSelector(instanceSelector, (instance) => {
    const { entities = {}, ...state } = instance || {};
    const instanceWithoutEntities = omit(state, ['isLoading', 'isLoadingList', 'isLoadingSiblings', 'siblings']);

    // Make sure the current instance is in the entities
    if (instance.id && !(instance.id in entities)) {
        return { ...entities, [instance.id]: instanceWithoutEntities };
    }
    return entities;
});

const getDefaultInstanceId = createSelector(instanceSelector, (instanceStore) => instanceStore.defaultInstanceId);

/** Retrieves the key/value object with the properties of the instance */
const getInstanceProperties = createSelector(getCurrentInstance, (instanceStore) => instanceStore.properties || {});

/**
 * Retrieves the instance available languages that are configured in the Advanced Settings section. By default, please
 * use getInstanceLanguages, use this only if you know what you are doing.
 */
const getInstanceAvailableLanguages = createSelector(getInstanceProperties, (properties) => properties.availableLangs);

const isPropertyEnabled = (token: string, group: string) =>
    createSelector(getInstanceProperties, getCustomerProperties, (instanceProperties, customerProperties) => {
        const instanceGroupProperties = instanceProperties[group];
        // Ignoring this TS error since customerProperties is a dynamic value, but TS wants to have group be a specific key.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const customerGroupProperties = customerProperties[group];

        if (instanceGroupProperties && instanceGroupProperties.length > 0) {
            const config = find(instanceGroupProperties, (field) => field && field.name === token);

            return Boolean(config) && config.enable;
        }

        if (customerGroupProperties && customerGroupProperties.length > 0) {
            const config = find(customerGroupProperties, (field) => field && field.name === token);

            return Boolean(config) && config.enable;
        }

        return true;
    });

/** retrieves the contentListLinkOpenNewTab in instance properties */
const getInstanceContentListLinkOpenNewTab = createSelector(
    getInstanceProperties,
    getCustomerProperties,
    (instanceProperties, customerProperties) => {
        const contentListLinkOpenNewTab =
            instanceProperties.contentListLinkOpenNewTab ??
            (customerProperties as CustomerProperties)?.contentListLinkOpenNewTab ??
            CONTENT_LIST_LINK_OPEN_NEW_TAB.NEW_TAB;

        return contentListLinkOpenNewTab === CONTENT_LIST_LINK_OPEN_NEW_TAB.NEW_TAB;
    },
);

/**
 * Retrieves the list of available languages configured for the current instance, taking into consideration
 * the ones configured for the customer. The logic is this:
 * - If there are any languages configured for the instance, use those
 * - If there aren't any, use the ones configured on the customer.
 * - If there aren't any, use the ones from default
 */
const getConfiguredAvailableLanguagesForInstanceProperties = (
    customerProperties: CustomerProperties,
    instanceProperties: CustomerProperties = {},
) => {
    const instanceGroupProperties = instanceProperties.availableLangs;
    const customerGroupProperties = customerProperties.availableLangs;

    return instanceGroupProperties || customerGroupProperties || defaultContributionLanguages;
};

/**
 * Retrieves the list of available languages configured for the current instance, taking into consideration
 * the ones configured for the customer. The logic is this:
 * - If there are any languages configured for the instance, use those
 * - If there aren't any, use the ones configured on the customer.
 * - If there aren't any, use the ones from default
 */
const getConfiguredInstanceAvailableLanguages = createSelector(
    getCustomerProperties,
    getInstanceProperties,
    (customerProperties, instanceProperties) => {
        return getConfiguredAvailableLanguagesForInstanceProperties(customerProperties, instanceProperties);
    },
);

const isAuthorDisplayable = isPropertyEnabled('author', 'contentFields');

/**
 * Get default instance from store if possible.
 */
const getDefaultInstance = createSelector(getInstanceById, getDefaultInstanceId, (entities, defaultInstanceId) => {
    if (!defaultInstanceId) {
        return undefined;
    }
    return entities[defaultInstanceId];
});

const isFavoriteSitesEnabled = isFeatureEnabled('instance-list-widget');

const getCurrentInstanceSummary = createSelector(getCurrentInstance, (instance) => {
    const { name, langs, title, subtitle, slug, publicContentAuthorized, id, isDefaultInstance, parent } = instance;

    return {
        name,
        langs,
        title,
        subtitle,
        slug,
        publicContentAuthorized,
        id,
        isDefaultInstance,
        parent,
    };
});

/** Retrieves the key/value object with the properties of the instance */
const getCurrentInstanceDefaultUserDirectory = createSelector(
    getCurrentInstance,
    (instanceStore) => instanceStore.defaultUserDirectory,
);

/** Retrieves the list of sites which are siblings and parent/children of the current instance */
const getCurrentInstanceSiblings = createSelector(instanceSelector, (instance) => values(instance?.siblings ?? {}));

/** Retrieves the loading state of a single instance */
const isLoadingInstance = createSelector(instanceSelector, (instance) => Boolean(instance?.isLoading));

/** Retrieves the loading state of multiple instances (list) */
const isLoadingInstancesList = createSelector(instanceSelector, (instance) => Boolean(instance?.isLoadingList));

/** Retrieves the loading state of instance siblings */
const isLoadingInstanceSiblings = createSelector(instanceSelector, (instance) => Boolean(instance?.isLoadingSiblings));

export {
    getInstanceById,
    instanceSelector as get,
    getSiteIds,
    getInstanceLanguages,
    getCurrentInstance,
    getInstanceLogo,
    getDefaultInstanceId,
    getDefaultInstance,
    getInstanceLogoProps,
    getInstanceProperties,
    getInstanceContentListLinkOpenNewTab,
    isAuthorDisplayable,
    isLoadingInstance,
    isLoadingInstanceSiblings,
    isLoadingInstancesList,
    isPropertyEnabled,
    isFavoriteSitesEnabled,
    getCurrentInstanceName,
    getHomePageId,
    instanceIdSelector,
    doesCurrentInstanceAllowPublicContent,
    getCurrentInstanceSummary,
    getCurrentInstanceParentId,
    getDefaultInstanceLanguage,
    getCurrentInstanceDefaultUserDirectory,
    getInstanceAvailableLanguages,
    getCurrentInstanceSiblings,
    getConfiguredInstanceAvailableLanguages,
    getConfiguredAvailableLanguagesForInstanceProperties,
    instanceSelector,
};
