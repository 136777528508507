import { AnalyticsProps } from '@lumapps/metric/types';

import { Route } from '../types';

export enum RouterStatus {
    idle = 'idle',
    loading = 'loading',
}

export enum ROUTER_DATA_NAMESPACES {
    USER_PROFILE = 'USER_PROFILE',
    BROADCAST_LIST = 'BROADCAST_LIST',
    CAMPAIGN_LIST = 'CAMPAIGN_LIST',
    JOURNEY_LIST = 'JOURNEY_LIST',
    COMMUNITY_PAGE = 'COMMUNITY_PAGE',
    SEARCH_PAGE = 'SEARCH_PAGE',
    ASK_AI_RULES_FORM = 'ASK_AI_RULES_FORM',
    PLAY_PAGE = 'PLAY_PAGE',
}

export interface RouterData {
    /** namespace for the data to be stored */
    id: ROUTER_DATA_NAMESPACES;
    /** data that will be saved on the given namespace */
    data: any;
    /** whether the data on the namespace should be merged or replaced */
    mergeData?: boolean;
}

export interface RouterState {
    status: RouterStatus;
    blockedRedirection?: {
        // The redirection route being blocked.
        blockedRoute?: Route;
        history?: any;
        // The url of the route blocking the redirections.
        blockingRoute?: string;
    };
    data: Record<string, any>;
    // Identifier of the page
    currentPage: string;
    // The current page title
    pageTitle?: string;
    // Custom info to fill the data layer
    analyticsProps?: AnalyticsProps;
}
