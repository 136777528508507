/**
 * Path of the icons on our CDN (both DEV and PROD CDNs).
 *
 * Increment the version number at the end of the path before each
 * re-generation and upload to CDN.
 *
 * @see /dev-packages/generate-icons/README.md for more info
 */
const ICONS_CDN_PATH = 'frontend-mdi-icons/v8';

/**
 * Name of the JSON file in the icons CDN that contains all the icons we want
 * to expose in our IconPicker
 */
const ICON_PICKER_LIST_FILE = 'icon-picker-icons.json';

module.exports = { ICONS_CDN_PATH, ICON_PICKER_LIST_FILE };
