import React from 'react';

import { useDispatch } from '@lumapps/redux/react';
import { actions } from '@lumapps/router/ducks/slice';

import { AnalyticsProps } from '../../types';

/**
 * Set custom analytics props to the dataLayerCustom
 * Use info from Page components
 * @param analyticsProps
 */
export const useAnalyticsProps = (analyticsProps?: AnalyticsProps) => {
    const dispatch = useDispatch();

    const shouldUpdate = analyticsProps && Boolean(analyticsProps.lumapps_entity_type);

    React.useEffect(() => {
        if (shouldUpdate) {
            dispatch(actions.setAnalyticsProps(analyticsProps));
        } else {
            dispatch(actions.setAnalyticsProps(undefined));
        }
    }, [dispatch, analyticsProps, shouldUpdate]);
};
