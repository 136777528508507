/* istanbul ignore file */
/** Still in development */
import React from 'react';

import { get } from '@lumapps/constants';
import { LoginPage } from '@lumapps/login/components/LoginPage/LoginPage';

const constants = get();

const Loading = () => <LoginPage logo={constants.logo || constants.defautlLogoUrl} isLoading displayPoweredBy />;

export { Loading };
