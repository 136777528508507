import React from 'react';

import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';

import { LumappsComponentIntegratedBaseProps } from '../../types';

export const LumappsComponentUnavailable: React.FC<LumappsComponentIntegratedBaseProps> = () => {
    // we need to add a proper error / component not handled state
    return <ServiceNotAvailableState shouldShowIcon />;
};
