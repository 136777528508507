import React from 'react';

import { ProgressLinear } from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'page-transition';
const PageTransition = () => <ProgressLinear className={CLASSNAME} />;

export { PageTransition };
