/* istanbul ignore file */
// Ignoring coverage for this file since it is just re-exports and definitions
// eslint-disable-next-line lumapps/do-not-import-react-query
import { QueryClient as BaseQueryClient, type QueryClientConfig } from '@tanstack/react-query';

import { useInfiniteQuery, type UseInfiniteQueryOptions, type UseInfiniteQueryResult } from './useInfiniteQuery';
import { useCustomMutation, type UseMutationOptions, type UseMutationResult } from './useMutation';
import { type UseQueryOptions, type UseQueryResult, useCustomQuery } from './useQuery';

/**
 * Create a default React Query client to ensure setup consistencies everywhere throughout apps and packages.
 *
 * ℹ️ We make sure `refetchOnWindowFocus`is disabled (can be overriden)
 * ℹ️ We make sure retries are disabled on both queries and mutations (can be overriden)
 */
export const createQueryClient = (config: QueryClientConfig | undefined = {}) =>
    new BaseQueryClient({
        ...config,
        defaultOptions: {
            ...config.defaultOptions,
            mutations: {
                retry: false,
                ...config.defaultOptions?.mutations,
            },
            queries: {
                retry: false,
                refetchOnWindowFocus: false,
                ...config.defaultOptions?.queries,
            },
        },
    });

/**
 * Make sure no one can initialize a query client without using our factory
 *
 * ℹ️ This export takes precedence over the `export * from '@tanstack/react-query'` statement below.
 */
export const QueryClient = undefined;

export * from '@tanstack/react-query';
export * from '@tanstack/react-query-devtools';

export { useInfiniteQuery };
export { useCustomQuery as useQuery };
export { useCustomMutation as useMutation };
export { type UseQueryOptions, type UseQueryResult };
export { type UseMutationOptions, type UseMutationResult };
export { type UseInfiniteQueryOptions, UseInfiniteQueryResult };
