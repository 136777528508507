/* istanbul ignore file */
import { ContentTypes } from '@lumapps/content-types/types';
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';
import { createPageRoute } from '@lumapps/router/utils';

export const directoryRoute: Route = createPageRoute({
    slug: ':slug',
    legacyId: 'app.front.content-get',
    appId: AppId.legacy,
});

export const directory = ({ slug, instanceSlug }: { slug: string; instanceSlug?: string }): Route => {
    const params = {
        slug,
    };

    return {
        ...directoryRoute,
        params,
        instanceSlug,
    };
};

export const getDirectoryPageMobilePageDescriptor = (id: string, title: string) => ({
    id,
    title,
    type: ContentTypes.DIRECTORY,
});
