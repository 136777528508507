import { FROALA_HTML_TOOLBAR } from './types';

export const DEFAULT_FROALA_HTML_TOOLBAR = [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'quote',
    'subscript',
    'superscript',
    'fontFamily',
    'fontSize',
    '|',
    'color',
    'inlineStyle',
    'paragraphStyle',
    '|',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'insertHR',
    '-',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    'insertPopin',
    'editPopin',
    'editContent',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
    'html',
];

export const FROALA_TOOL_ICONS: Record<string, string> = {
    align: 'format-align-left',
    'align-center': 'format-align-center',
    'align-justify': 'format-align-justify',
    'align-left': 'format-align-left',
    'align-right': 'format-align-right',
    bold: 'format-bold',
    clearFormatting: 'format-clear',
    color: 'format-color-fill',
    editContent: 'pencil-box',
    editPopin: 'message-draw',
    emoticons: 'emoticon-happy',
    fontFamily: 'format-text',
    fontSize: 'format-size',
    formatOL: 'format-list-numbered',
    formatUL: 'format-list-bulleted',
    fullscreen: 'fullscreen',
    fullscreenCompress: 'fullscreen-exit',
    html: 'code-tags',
    'image-align': 'format-align-left',
    'image-align-center': 'format-align-justify',
    'image-align-left': 'format-align-left',
    'image-align-right': 'format-align-right',
    imageAlt: 'tooltip-image',
    imageRemove: 'delete',
    imageSize: 'arrow-expand-all',
    imageStyle: 'auto-fix',
    indent: 'format-indent-increase',
    inlineStyle: 'brush',
    insertFile: 'file',
    insertHR: 'color-helper',
    insertImage: 'image-area',
    insertLink: 'link',
    insertPopin: 'message-plus',
    insertTable: 'table-large',
    insertVideo: 'video',
    italic: 'format-italic',
    linkContent: 'content-duplicate',
    linkEdit: 'pencil',
    linkOpen: 'open-in-new',
    linkRemove: 'link-off',
    linkStyle: 'auto-fix',
    outdent: 'format-indent-decrease',
    paragraphFormat: 'format-paragraph',
    paragraphStyle: 'auto-fix',
    quote: 'format-quote-close',
    redo: 'redo',
    selectAll: 'select-all',
    strikeThrough: 'format-strikethrough',
    subscript: 'format-subscript',
    superscript: 'format-superscript',
    tableCellBackground: 'format-color-fill',
    tableCellHorizontalAlign: 'format-horizontal-align-left',
    tableCellStyle: 'auto-fix',
    tableCellVerticalAlign: 'format-vertical-align-top',
    tableCells: 'crop-square',
    tableColumns: 'table-column-plus-after',
    tableHeader: 'format-title',
    tableRemove: 'delete',
    tableRows: 'table-row-plus-after',
    tableStyle: 'brush',
    underline: 'format-underline',
    undo: 'undo',
    'video-align': 'format-align-left',
    'video-align-center': 'format-align-center',
    'video-align-left': 'format-align-left',
    'video-align-right': 'format-align-right',
    videoEmbed: 'code-tags',
    videoRemove: 'delete',
    videoReplace: 'refresh',
    videoSize: 'arrow-expand-all',
};

const FROALA_TRANSLATION_KEY_PREFIX = 'FRONT.FROALA.';

export const VERTICAL_SEPARATOR: FROALA_HTML_TOOLBAR = {
    icon: 'format-title',
    name: 'VERTICAL.SEPARATOR',
    unique: false,
    translationKey: `${FROALA_TRANSLATION_KEY_PREFIX}VERTICAL.SEPARATOR`,
    id: '|',
};

export const HORIZONTAL_SEPARATOR: FROALA_HTML_TOOLBAR = {
    icon: 'subdirectory-arrow-left',
    name: 'HORIZONTAL.SEPARATOR',
    unique: false,
    translationKey: `${FROALA_TRANSLATION_KEY_PREFIX}HORIZONTAL.SEPARATOR`,
    id: '-',
};

export const getToolbarForToolId = (toolId: string) => {
    const toolIdInUpperCase = toolId.toUpperCase();

    switch (toolId) {
        case '-':
            // Horizontal separator exception.
            return HORIZONTAL_SEPARATOR;
        case '|':
            // Vertical separator exception.
            return VERTICAL_SEPARATOR;
        default:
            // For all other tools, get icon from Froala icons override.
            return {
                icon: FROALA_TOOL_ICONS[toolId],
                name: toolIdInUpperCase,
                translationKey: `${FROALA_TRANSLATION_KEY_PREFIX}${toolIdInUpperCase}`,
                unique: true,
                id: toolId,
            };
    }
};

/**
 * Build the Froala toolbar choices.
 *
 * @return {Object} The toolbar choices.
 */
export const getWysiwygToolbar = () => {
    const toolObject: Record<string, FROALA_HTML_TOOLBAR> = {};

    DEFAULT_FROALA_HTML_TOOLBAR.forEach((toolId) => {
        toolObject[toolId] = getToolbarForToolId(toolId);
    });

    return toolObject;
};

export const FroalaSettings = {
    // Available font size.
    fontSize: [
        '8',
        '9',
        '10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '18',
        '20',
        '22',
        '24',
        '26',
        '28',
        '30',
        '32',
        '36',
        '40',
        '46',
        '52',
        '60',
        '72',
        '96',
    ],
    // Available default fonts.
    fonts: {
        "'Roboto', sans-serif": 'Roboto',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Arial,Helvetica,sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        'Verdana,Geneva,sans-serif': 'Verdana',
    },
    // Links options.
    linkEditButtons: ['linkEdit', 'linkRemove'],
    linkInsertButtons: ['linkBack'],
    quickInsertEnabled: false,
    key: 'GPD2tA9B2A3A2D3B2D2lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1B1H4A1B2B1D7A5==',
    // Available SVG attributes.
    svgAttributes: [
        'accent-height',
        'accumulate',
        'additive',
        'alignment-baseline',
        'allowReorder',
        'alphabetic',
        'amplitude',
        'arabic-form',
        'ascent',
        'attributeName',
        'attributeType',
        'autoReverse',
        'azimuth',
        'baseFrequency',
        'baseline-shift',
        'baseProfile',
        'bbox',
        'begin',
        'bias',
        'by',
        'calcMode',
        'cap-height',
        'class',
        'clip',
        'clipPathUnits',
        'clip-path',
        'clip-rule',
        'color',
        'color-interpolation',
        'color-interpolation-filters',
        'color-profile',
        'color-rendering',
        'contentScriptType',
        'contentStyleType',
        'cursor',
        'cx',
        'cy',
        'd',
        'decelerate',
        'descent',
        'diffuseConstant',
        'direction',
        'display',
        'divisor',
        'dominant-baseline',
        'dur',
        'dx',
        'dy',
        'edgeMode',
        'elevation',
        'enable-background',
        'end',
        'exponent',
        'externalResourcesRequired',
        'fill',
        'fill-opacity',
        'fill-rule',
        'filter',
        'filterRes',
        'filterUnits',
        'flood-color',
        'flood-opacity',
        'font-family',
        'font-size',
        'font-size-adjust',
        'font-stretch',
        'font-style',
        'font-variant',
        'font-weight',
        'format',
        'from',
        'fr',
        'fx',
        'fy',
        'g1',
        'g2',
        'glyph-name',
        'glyph-orientation-horizontal',
        'glyph-orientation-vertical',
        'glyphRef',
        'gradientTransform',
        'gradientUnits',
        'hanging',
        'hreflang',
        'horiz-adv-x',
        'horiz-origin-x',
        'ideographic',
        'image-rendering',
        'in',
        'in2',
        'intercept',
        'k',
        'k1',
        'k2',
        'k3',
        'k4',
        'kernelMatrix',
        'kernelUnitLength',
        'kerning',
        'keyPoints',
        'keySplines',
        'keyTimes',
        'lengthAdjust',
        'letter-spacing',
        'lighting-color',
        'limitingConeAngle',
        'local',
        'marker-end',
        'marker-mid',
        'marker-start',
        'markerHeight',
        'markerUnits',
        'markerWidth',
        'mask',
        'maskContentUnits',
        'maskUnits',
        'mathematical',
        'method',
        'mode',
        'numOctaves',
        'offset',
        'opacity',
        'operator',
        'order',
        'orient',
        'orientation',
        'origin',
        'overflow',
        'overline-position',
        'overline-thickness',
        'panose-1',
        'paint-order',
        'path',
        'pathLength',
        'patternContentUnits',
        'patternTransform',
        'patternUnits',
        'ping',
        'pointer-events',
        'points',
        'pointsAtX',
        'pointsAtY',
        'pointsAtZ',
        'preserveAlpha',
        'preserveAspectRatio',
        'primitiveUnits',
        'r',
        'radius',
        'referrerPolicy',
        'refX',
        'refY',
        'rel',
        'rendering-intent',
        'repeatCount',
        'repeatDur',
        'requiredExtensions',
        'requiredFeatures',
        'restart',
        'result',
        'rotate',
        'rx',
        'ry',
        'scale',
        'seed',
        'shape-rendering',
        'slope',
        'spacing',
        'specularConstant',
        'specularExponent',
        'speed',
        'spreadMethod',
        'startOffset',
        'stdDeviation',
        'stemh',
        'stemv',
        'stitchTiles',
        'stop-color',
        'stop-opacity',
        'strikethrough-position',
        'strikethrough-thickness',
        'string',
        'stroke',
        'stroke-dasharray',
        'stroke-dashoffset',
        'stroke-linecap',
        'stroke-linejoin',
        'stroke-miterlimit',
        'stroke-opacity',
        'stroke-width',
        'surfaceScale',
        'systemLanguage',
        'tableValues',
        'target',
        'targetX',
        'targetY',
        'text-anchor',
        'text-decoration',
        'text-rendering',
        'textLength',
        'to',
        'transform',
        'type',
        'u1',
        'u2',
        'underline-position',
        'underline-thickness',
        'unicode',
        'unicode-bidi',
        'unicode-range',
        'units-per-em',
        'v-alphabetic',
        'v-hanging',
        'v-ideographic',
        'v-mathematical',
        'values',
        'vector-effect',
        'version',
        'vert-adv-y',
        'vert-origin-x',
        'vert-origin-y',
        'viewBox',
        'viewTarget',
        'visibility',
        'widths',
        'word-spacing',
        'writing-mode',
        'x',
        'x-height',
        'x1',
        'x2',
        'xChannelSelector',
        'xlink:actuate',
        'xlink:arcrole',
        'xlink:href',
        'xlink:role',
        'xlink:show',
        'xlink:title',
        'xlink:type',
        'xml:base',
        'xml:lang',
        'xml:space',
        'xmlns',
        'xmlns:xlink',
        'y',
        'y1',
        'y2',
        'yChannelSelector',
        'z',
        'zoomAndPan',
    ],
    // Available SVG tags.
    svgTags: [
        'altGlyph',
        'altGlyphDef',
        'altGlyphItem',
        'animate',
        'animateColor',
        'animateMotion',
        'animateTransform',
        'circle',
        'clipPath',
        'color-profile',
        'cursor',
        'defs',
        'desc',
        'discard',
        'ellipse',
        'feBlend',
        'feColorMatrix',
        'feComponentTransfer',
        'feComposite',
        'feConvolveMatrix',
        'feDiffuseLighting',
        'feDisplacementMap',
        'feDistantLight',
        'feDropShadow',
        'feFlood',
        'feFuncA',
        'feFuncB',
        'feFuncG',
        'feFuncR',
        'feGaussianBlur',
        'feImage',
        'feMerge',
        'feMergeNode',
        'feMorphology',
        'feOffset',
        'fePointLight',
        'feSpecularLighting',
        'feSpotLight',
        'feTile',
        'feTurbulence',
        'filter',
        'font',
        'font-face',
        'font-face-format',
        'font-face-name',
        'font-face-src',
        'font-face-uri',
        'foreignObject',
        'g',
        'glyph',
        'glyphRef',
        'hatch',
        'hatchpath',
        'hkern',
        'image',
        'line',
        'linearGradient',
        'marker',
        'mask',
        'mesh',
        'meshgradient',
        'meshpatch',
        'meshrow',
        'metadata',
        'missing-glyph',
        'mpath',
        'path',
        'pattern',
        'polygon',
        'polyline',
        'radialGradient',
        'rect',
        'set',
        'solidcolor',
        'stop',
        'style',
        'svg',
        'switch',
        'symbol',
        'text',
        'textPath',
        'title',
        'tref',
        'tspan',
        'use',
        'view',
        'vkern',
    ],
};
