/**
 * The different possible statuses of the GenericEntityPicker.
 */
export enum BaseLoadingStatus {
    /**
     * Initial state.
     * This is the default state of the component.
     * This state should be use to trigger any action to do on mount for example.
     */
    initial = 'initial',
    /**
     * Idle State.
     * This state means that nothing is happening but
     * something *has* happened previously and the component has now returned to
     * a neutral state.
     */
    idle = 'idle',
    /**
     * Empty state
     * This state means that the component is empty because there was no result,
     * without any error or filter.
     */
    empty = 'empty',
    /**
     * Empty filtered state
     * This state is like Empty, but with the difference that the component has been filtered.
     */
    emptyFiltered = 'emptyFiltered',
    /**
     * Empty initially state
     * This state is like Empty, and it's used in component that has multiple fetches and the first is empty.
     */
    emptyInitially = 'emptyInitially',
    /**
     * Debouncing state
     * This state means that the component is currently waiting for the fetch to be triggered.
     */
    debouncing = 'debouncing',
    /**
     * Loading State
     * This state means that the component is currently loading.
     */
    loading = 'loading',
    /**
     * Loading More state.
     * This state means that the component is currently loading more.
     */
    loadingMore = 'loadingMore',
    /**
     * Error State.
     * This state means that something went wrong and an error should be displayed.
     */
    error = 'error',
    /**
     * Saving state
     * This state means that the component is currently saving an entity. It is different than loading
     * since loading might be loading for the first time, and saving would display a different loading state.
     */
    saving = 'saving',
    /**
     * Filtering state
     * This state means that the component is currently filtering entities. It is different than loading
     * since loading might be loading for the first time, and filtering would display a different loading state.
     */
    filtering = 'filtering',
    /**
     * Deleting state
     * This state means that the component is currently deleting entities.
     */
    deleting = 'deleting',
}

/**
 * Useful when retrieving data from API call,
 * when status is idle, data should be available.
 */
export type WithStatus<DataType> =
    | {
          status: Exclude<BaseLoadingStatus, BaseLoadingStatus.idle>;
      }
    | ({
          status: BaseLoadingStatus.idle;
      } & DataType);

/**
 * Check if the status is initial or loading
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isLoading = (currentStatus: string) =>
    currentStatus === BaseLoadingStatus.initial || currentStatus === BaseLoadingStatus.loading;

/**
 * Check if the status is loading (only)
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isLoadingStrict = (currentStatus: string) => currentStatus === BaseLoadingStatus.loading;

/**
 * Check if the status is loadingMore
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isLoadingMore = (currentStatus: string) => currentStatus === BaseLoadingStatus.loadingMore;

/**
 * Check if the status is error
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isError = (currentStatus: string) => currentStatus === BaseLoadingStatus.error;

/**
 * Check if the status is empty
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isEmpty = (currentStatus: string) => currentStatus === BaseLoadingStatus.empty;

/**
 * Check if the status is emptyFiltered
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isEmptyFiltered = (currentStatus: string) => currentStatus === BaseLoadingStatus.emptyFiltered;

/**
 * Check if the status is saving
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isSaving = (currentStatus: string) => currentStatus === BaseLoadingStatus.saving;

/**
 * Check if the status is idle
 * @param {string} currentStatus
 * @returns {boolean}
 */
export const isIdle = (currentStatus: string) => currentStatus === BaseLoadingStatus.idle;
