import { useMutation, useQueryClient } from '@lumapps/base-api/react-query';

import { microAppsQueryKeys, submitRunMicroApp } from '../api';

/**
 * Hook to manage submitting a micro app.
 * Returns a react-query mutation object.
 */
export const useSubmitRunMicroApp = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: submitRunMicroApp,
        onSuccess: async (data, variables) => {
            /**
             * Once the micro app has successfully been updated, set the new value in the queryClient.
             */
            queryClient.setQueryData(
                microAppsQueryKeys.runMicroApp(
                    variables.microAppId,
                    variables.runInstanceId,
                    variables.inputVariables || {},
                ),
                data,
            );
        },
    });
};
