/* istanbul ignore file */
/** Still in development */
import { injectAvailableLanguages } from '@lumapps/customer/transforms';
import { injectInstanceFromWindow } from '@lumapps/instance/transforms';
import { injectLocale } from '@lumapps/languages/transforms';
import { injectTokenIntoUser } from '@lumapps/user/transforms';

const keysToIgnore = ['content'];

const transforms = [injectInstanceFromWindow, injectLocale, injectTokenIntoUser, injectAvailableLanguages];

export { transforms, keysToIgnore };
