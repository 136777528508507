import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { G_RECAPTCHA_KEY } from '@lumapps/constants';

export interface RecaptchaProps {
    /**  change the reference of the captcha */
    handleChange?: (param: any) => void;
    className?: string;
    form?: string;
}
/**
 * reCAPTCHA v2 ("I'm not a robot" Checkbox)
 *
 * @family Inputs
 * @param RecaptchaProps
 * @returns ReCaptcha
 */
export const ReCaptcha: React.FC<RecaptchaProps> = ({ handleChange, className, form }) => {
    const captchaRef = React.useRef().current;
    return (
        <ReCAPTCHA
            className={className}
            ref={captchaRef}
            sitekey={G_RECAPTCHA_KEY}
            onChange={handleChange}
            // add this typeScript ignore because Recaptcha cannot read external properties,
            // for this example, we have need form property for a specific accessibility need
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            form={form}
        />
    );
};
/**
 * reset Recaptcha
 */
export function resetCaptcha() {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    return window.grecaptcha.reset();
}
