import React from 'react';

import { LoginPage } from '@lumapps/login/components/LoginPage';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { SIGN_IN } from '@lumapps/sign-in/keys';
import { useTranslate } from '@lumapps/translations';

import { ResetPasswordPage } from '../ResetPassword';
/**  ResetPasswordStep: Component which render the step of reset password.
 * Reset password contains a form with new password and confirm password. */
export const ResetPasswordStep: React.FC<StepProps> = () => {
    const { translateKey } = useTranslate();
    return (
        <LoginPage title={translateKey(SIGN_IN.RESET_YOUR_PASSWORD)} displayPoweredBy>
            <ResetPasswordPage />
        </LoginPage>
    );
};
