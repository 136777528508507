/* eslint-disable default-param-last */
import BaseApi from '@lumapps/base-api';
import { configureStore } from '@lumapps/redux/manager';

import { transformState } from './transformState';

/**
 * Initializes the redux store
 * @param rootReducer - reducer that will be used with the store. It should combine any other reducers that you have in your app
 * @param initialState - Raw initial state of the redux store
 * @param transforms - transformations that you want to apply to your store before initializing it
 * @param keysToIgnore - keys from the initial state that you do not want to have on the final store
 */
const initStore = (rootReducer: any, initialState = {}, transforms: any = [], keysToIgnore?: string[]) => {
    const initialStateKeys = Object.keys(initialState);

    const state = transformState(initialState, transforms, [
        // Remove the keys that do not have associated reducer
        ...initialStateKeys.filter((key: string) => !rootReducer[key]),
        ...(keysToIgnore || []),
    ]);
    const store = configureStore(state, rootReducer);

    BaseApi.store = store;

    return store;
};

export { initStore };
