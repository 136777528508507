const injectTokenIntoUser = (initState: any = {}) => {
    const token = window.USER_ACCESS_TOKEN;

    if (initState.user) {
        Object.assign(initState.user, {
            token,
            initialize: true,
            connectedUser: { ...initState.user, token },
        });
    }

    if (initState.customer) {
        const { customer } = initState;

        Object.assign(initState, {
            feed: {
                ALL: customer.feedAll,
                PUBLIC: customer.feedPublic,
                entities: {},
            },
        });
    }

    return initState;
};

export { injectTokenIntoUser };
