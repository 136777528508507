import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SIGN_IN_CORE } from '../keys';
import { LoginPageProps } from '../LoginPage/LoginPageProps';

import './index.scss';

const { defaultLogo, publicPath } = get();
const CLASSNAME = 'powered-by';
export const PoweredBy: React.FC<Pick<LoginPageProps, 'isLoading'>> = ({ isLoading }) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    if (!isLoading) {
        return (
            <div className={block()}>
                <Text as="p" typography="body1" color="dark" colorVariant="L2" className={element('text-powered-by')}>
                    {translateKey(SIGN_IN_CORE.POWERED_BY)}
                    <img
                        className={element('logo-powered-by')}
                        src={`${publicPath}${defaultLogo}`}
                        alt={translateKey(GLOBAL.LUMAPPS)}
                    />
                </Text>
            </div>
        );
    }
    return null;
};
