import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiArrowLeft } from '@lumapps/lumx/icons';
import { Button, Emphasis } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { useWizard } from '../../hooks/useWizard';
import { UseWizardProps } from '../../types';

export interface WizardProps<T> extends UseWizardProps<T> {
    /** wizard state */
    state?: T;
    /** Avoid adding a wrapper to the component.
     * WARNING: if you use this, you will need to focus on the step by hand using useFocus
     */
    noComponentWrapper?: boolean;
}

const CLASSNAME = 'wizard';
/**
 * Displays a list of steps in a wizard fashion, controlling current, previous and next steps.
 *
 * @family Layouts
 * @param WizardProps
 * @returns Wizard
 */
export const Wizard = <T,>({
    steps,
    state,
    startingStep,
    startingStepId,
    noComponentWrapper = false,
}: WizardProps<T>) => {
    const { translateKey } = useTranslate();
    const [wizardState, setWizardState] = React.useState(state);
    const { element } = useClassnames(CLASSNAME);

    const { step, goToNextStep, goToPreviousStep, index, isFirstStep, isLastStep, focusRef } = useWizard({
        steps,
        startingStep,
        startingStepId,
    });

    const { Component } = step;

    const RenderedComponent = (
        <Component
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            state={wizardState}
            setWizardState={setWizardState}
            step={step}
            index={index}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            backButton={
                <Button
                    className={element('back-button')}
                    leftIcon={mdiArrowLeft}
                    emphasis={Emphasis.low}
                    onClick={goToPreviousStep}
                >
                    {translateKey(GLOBAL.BACK)}
                </Button>
            }
        />
    );

    return noComponentWrapper ? (
        RenderedComponent
    ) : (
        <div tabIndex={-1} ref={focusRef} className={CLASSNAME}>
            {RenderedComponent}
        </div>
    );
};
