import React from 'react';

import { useSelector } from '@lumapps/redux/react';

import { routerIsLoading } from '../../ducks/selectors';
import { PageTransition } from './PageTransition';

/**
 * This component is directly connected to the "status" field of the router slice.
 * It should be used when an action has to be made before redirecting a user.
 *
 * To control it, use the `showLoader` and `hideLoader` actions from the slice.
 *
 * Ex:
 *   // SHOW ROUTING LOADER
 *   dispatch(routerActions.showLoader());
 *
 *   try {
 *       const response = await someAsyncFunc();
 *        dispatch(someCustomThunk());
 *       // HIDE LOADER
 *       dispatch(routerActions.hideLoader());
 *       angularApi.redirect(someRoute());
 *
 *   } catch (error) {
 *       // HIDE LOADER IN CASE OF ERROR
 *       dispatch(routerActions.hideLoader());
 *       dispatch(someErrorThunk());
 *   }
 */
export const ConnectedPageTransition = () => {
    const isLoading = useSelector(routerIsLoading);

    return isLoading ? <PageTransition /> : null;
};
