import React, { useState, FocusEvent } from 'react';

import isEmpty from 'lodash/isEmpty';

import { useClassnames } from '@lumapps/classnames';
import { sanitizeLoginStepFormUrl } from '@lumapps/login/utils/urlSanitation';
import { mdiEyeOutline } from '@lumapps/lumx/icons';
import { TextField, Button, Icon, Size, Link } from '@lumapps/lumx/react';
import { useQueryParams } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isValidEmail } from '@lumapps/utils/string/isValidEmail';

import { SIGN_IN } from '../../keys';
import { STEPS } from '../../routes';
import { LoginMessage } from '../LoginMessage';

import './index.scss';

export interface SignInFormProps {
    defaultEmail?: string;
    defaultPassword?: string;
    goToNextStep?: () => void;
}
const CLASSNAME = 'sign-in-form';
export const SignInForm: React.FC<SignInFormProps> = ({ defaultEmail = '', defaultPassword = '', goToNextStep }) => {
    const { translateKey } = useTranslate();
    const { r: redirectUri } = useQueryParams();
    const { element, block } = useClassnames(CLASSNAME);
    const [errorEmail, setErrorEmail] = useState<string>('');
    const [email, setEmail] = useState<string>(defaultEmail);
    const [password, setPassword] = useState<string>(defaultPassword);
    const [isPasswordHidden, setPasswordHidden] = useState<boolean>(true);

    /**
     * Prevent to POST the form without a valid email when the user validate the
     * form with the ENTER key.
     * @param evt
     */
    const handleSubmit = (evt: React.SyntheticEvent) => {
        if (!isValidEmail(email)) {
            evt.preventDefault();
        }
    };

    /**
     * Set an error if the email is not valid
     */
    const controlEmailAddress = (evt: FocusEvent<HTMLInputElement>) => {
        if (isEmpty(evt)) {
            return;
        }

        if (!isValidEmail(email)) {
            setErrorEmail(translateKey(SIGN_IN.INVALID_EMAIL_ERROR));
        } else {
            setErrorEmail('');
        }
    };

    return (
        <>
            <form
                className={block()}
                action={sanitizeLoginStepFormUrl(STEPS.SIGN_IN, window.location.pathname, window.location.search)}
                method="POST"
                onSubmit={handleSubmit}
            >
                <TextField
                    label={translateKey(SIGN_IN.EMAIL_ADDRESS)}
                    error={errorEmail}
                    hasError={!!errorEmail}
                    name="login"
                    id="login"
                    value={email}
                    onChange={setEmail}
                    onBlur={controlEmailAddress}
                    className={element('first-input')}
                />

                <TextField
                    label={translateKey(SIGN_IN.ADMIN_USER_DIRECTORY_PASSWORD)}
                    className={element('input')}
                    type={isPasswordHidden ? 'password' : 'text'}
                    name="password"
                    id="password"
                    value={password}
                    onChange={setPassword}
                    afterElement={
                        <Icon
                            className={element('eye-password-icon')}
                            icon={mdiEyeOutline}
                            size={Size.xxs}
                            onClick={() => {
                                setPasswordHidden(!isPasswordHidden);
                            }}
                        />
                    }
                />

                <input id="redirectUri" type="hidden" name="redirectUri" value={redirectUri} />

                <input id="loginMethod" type="hidden" name="loginMethod" value="email" />

                <Button
                    disabled={!password || !isValidEmail(email)}
                    className={element('button-form')}
                    type="submit"
                    fullWidth
                >
                    {translateKey(GLOBAL.SIGN_IN)}
                </Button>

                <LoginMessage />
            </form>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className={element('forgot-password-link')} onClick={goToNextStep} typography="body1">
                {translateKey(SIGN_IN.FORGOT_YOUR_PASSWORD)}
            </Link>
        </>
    );
};
