/* eslint-disable default-param-last */
import omit from 'lodash/omit';

const transformState = (state: any = {}, transforms: any = [], keysToIgnore?: string[]) => {
    const transformedState = transforms.reduce(
        (acc: any, transform: (arg0: any) => any) => ({
            ...acc,
            ...transform(acc),
        }),
        state,
    );

    return keysToIgnore && keysToIgnore.length > 0 ? omit(transformedState, keysToIgnore) : transformedState;
};

export { transformState };
