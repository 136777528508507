/* istanbul ignore file */
import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';
import { URL_PREFIX } from '@lumapps/router/constants';

export const UserDirectoryRoute: Route = {
    path: `/${URL_PREFIX}/user-directory/:contentId/:slug`,
    appId: AppId.frontOffice,
};

/** User directory mobile page descriptor */
export const getUserDirectoryMobilePageDescriptor = (id: string): Route['mobilePageDescriptor'] => ({
    type: 'user-directory',
    id,
});

/** Retrieves the route for the users directory page */
export const userDirectoryView = ({
    contentId,
    slug,
    instanceSlug,
}: {
    contentId: string;
    slug: string;
    instanceSlug?: string;
}): Route => {
    return {
        ...UserDirectoryRoute,
        params: { contentId, slug },
        instanceSlug,
        mobilePageDescriptor: getUserDirectoryMobilePageDescriptor(contentId),
    };
};
