import { RefObject } from 'react';

import { GLOBAL } from '@lumapps/translations';

export * from './sorts';
export * from './zendesk';
export * from './engines';
export * from './filters';
export * from './types';

/**
 * Default size page for the search engine, this determines the amount of
 * search results that we will show on the main page.
 */
export const MAX_SEARCH_RESULTS = 10;

/**
 * Search engine can have some restrictions in therm of how many pages we can fetch
 * We think that 100 pages is enougth to find something in every search engine
 */
export const MAX_SEARCH_PAGES = 100;

/**
 * Default size page for the aside results, this determines the amount of
 * aside results that we will show on each side result cluster.
 */
export const MAX_ASIDE_SEARCH_RESULTS = 4;

/**
 * Max queries saved in the history
 */
export const MAX_SEARCH_HISTORY_SUGGEST_RESULTS = 3;
export const MAX_SEARCH_INTERACTIONS_SUGGEST_RESULTS = 3;

export const MAX_SEARCH_SUGGEST_RESULTS = 5;
export const MAX_SEARCH_SUGGEST_QUERIES = 3;

export const MAX_SEARCH_HISTORY_STORED_SUGGEST_RESULTS = 50;
export const MAX_SEARCH_INTERACTIONS_STORED_SUGGEST_RESULTS = 50;

export const MAX_ACTION_HISTORY_STORED = 20;

/**
 * The default filter for the search page. If this filter is currently selected,
 * it means that the current search has no filters whatsoever.
 */
export const DEFAULT_FILTER = 'all';
export const RESET_FILTER = 'FILTER_RESET';

/**
 * The prefix that will be used to determine whether a query param is a facet or not.
 * Since on the URL we could have A LOT of query params, we need to have a way to filtering
 * the query params that we have in order to apply them.
 */
export const FACET_PREFIX = 'facet.';

/**
 * API timeout that will be used in order abort API calls when they take a lot of time to respond
 */
export const SEARCH_CLUSTER_API_TIMEOUT = 3000;
export const SEARCH_API_TIMEOUT = 6000;

/**
 * Returns the facet that will be used as a query param.
 * @param facet - facet to be applied
 */
export const getPrefixedFacet = (facet: string) => `${FACET_PREFIX}${facet}`;

/**
 * filter that can be applied to determine whether a query param is a facet or not
 * @param key - key of the query param. for example, for the query param facet.type=article => facet.type
 * @param value - value of the query param. for example, for the query param facet.type=article => article
 */
export const facetParamsFilter = (key: string, value: string) => {
    return key.indexOf(FACET_PREFIX) === 0 && Boolean(value);
};

/**
 * Type of share point results that will be displayed on the page.
 */
export enum SHARE_POINT_RESULT_TYPES {
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
}

/**
 * Status that the search page could have.
 */
export enum STATUS {
    /** status that will be used while the search page is loading */
    LOADING = 'loading',
    /** status that will be used while the user is browsing the search results */
    BROWSING = 'browsing',
    /** status that will be used when the search API returned an error result. */
    ERROR = 'error',
    /** status that will be used when the we are downloading a file from the API */
    DOWNLOADING = 'downloading',
    /** status that will be used when there is a current filter being applied, before loading */
    FILTERING = 'filtering',
}

export interface SharingDetailsType {
    /** icon to display */
    icon: string;
    /** id of the type */
    id: string;
    /** translation key to use for the description */
    description: string;
    /** translation key to use for the details label */
    label: string;
}

export const SHARING_DETAILS_TYPES: Record<string, SharingDetailsType> = {
    TEAM_DRIVE: {
        id: 'TEAM_DRIVE',
        icon: 'account-box',
        description: '',
        label: GLOBAL.GOOGLE_TEAM_DRIVE,
    },
    LIMITED: {
        id: 'LIMITED',
        icon: 'account-multiple',
        description: GLOBAL.SHARED,
        label: GLOBAL.SHARED,
    },
    PRIVATE: {
        id: 'PRIVATE',
        icon: 'lock',
        description: 'FRONT.GOOGLE_DRIVE_PRIVACY.PRIVATE_DESCRIPTION',
        label: GLOBAL.PRIVATE,
    },
    PUBLIC: {
        id: 'PUBLIC',
        icon: 'earth',
        description: 'FRONT.GOOGLE_DRIVE_PRIVACY.PUBLIC_DESCRIPTION',
        label: GLOBAL.PUBLIC,
    },
    PUBLIC_WITH_LINK: {
        id: 'PUBLIC_WITH_LINK',
        icon: 'link',
        description: 'FRONT.GOOGLE_DRIVE_PRIVACY.PUBLIC_WITH_LINK_DESCRIPTION',
        label: GLOBAL.PUBLIC_LINK,
    },
};

export const SEARCH_HISTORY_CACHE_KEY = 'search-history';
export const SEARCH_INTERACTIONS_CACHE_KEY = 'search-interactions';
export const SEARCH_ACTIONS_HISTORY_CACHE_KEY = 'search-actions';

declare global {
    interface Window {
        /**
         * Reference to the search input of the front-office application
         */
        SEARCH_INPUT?: RefObject<HTMLInputElement>;
    }
}

export const SEARCH_GROUPS_RESTRICTIONS_PARAM = 'useGroupsRestrictions';
export const HIGHLIGHT_WRAPPER_TAG_START = '<mark class="search-result-highlight">';
export const HIGHLIGHT_WRAPPER_TAG_END = '</mark>';
export const SEARCH_FIRST_PAGE = 0;
export const SEARCH_PAGE_PARAM = 'page';
export enum SEARCH_FORMAT {
    JSON = '',
    CSV = 'csv',
}
export const SEARCH_EXPORT_PAGE_FILENAME = 'search.csv';

export const ACTION_HISTORY_NAME = 'Query';
export const SEARCH_PAGE_ID = 'search';
export enum CUTOMIZATION_EVENTS_SEARCH_CAUSE {
    FILTER_INTERACTION = 'filter-interaction',
    SORT_INTERACTION = 'sort-interaction',
    FETCH_RESULTS = 'fetch-results',
    SEARCHBOX_INTERACTION = 'searchbox-interaction',
}

export const GCS_INDEXING_FEATURE_TOKEN = 'cloudsearch-indexing';
