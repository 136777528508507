import React from 'react';

import { AskAiCardContentProps } from '@lumapps/ask-ai/components/AskAiCard/AskAiCardContent';
import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { IAdaptiveCard } from '@lumapps/lumx-card-renderer';
import { RestrictedAccessState } from '@lumapps/lumx-states/components/RestrictedAccessState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { LumappsComponent } from '@lumapps/micro-apps-integrations/components/LumappsComponent';
import { LumappsComponentInput } from '@lumapps/micro-apps-integrations/types';
import { useTranslate } from '@lumapps/translations';

import { MICRO_APP_CORE } from '../../keys';
import { OpenUrlAction, SubmitMicroAppAction } from '../../types';
import { AdaptiveCard } from '../AdaptiveCard';
import { LoadingState } from '../AdaptiveCard/LoadingState';

import './index.scss';

export interface MicroAppCardProps {
    /**
     * The adaptive card to display
     */
    adaptiveCard?: IAdaptiveCard;
    /**
     * Additional class name to apply to the card
     */
    className?: string;
    /**
     * Whether the card has an error
     */
    hasError: boolean;
    /**
     * The id of the card
     */
    id?: string;
    /**
     * Whether the card is loading
     */
    isLoading: boolean;
    /**
     * If error is 403, display a specific error state
     */
    isNotAuthorized: boolean;
    /**
     * The lumapps component to display
     */
    lumappsComponent?: LumappsComponentInput;

    /**
     * Callback called when the user submits the card
     */
    onSubmit: (action: SubmitMicroAppAction) => void;
    /* Callback called when the user click on an url. */
    onOpenUrl: (action: OpenUrlAction) => void;
    /* Callback called when the card is loaded. */
    setIsLoaded?: AskAiCardContentProps['setIsLoaded'];
}

const SCOPE = 'micro-app';

/**
 * Card displaying the provided micro-app
 * Can display an error or a loading state
 * Has customizable className and onSubmit call
 *
 * @family Card
 * @param MicroAppCardProps
 * @returns MicroAppCard
 */
export const MicroAppCard: React.FC<MicroAppCardProps> = ({
    adaptiveCard,
    className,
    hasError,
    id,
    isLoading,
    isNotAuthorized,
    lumappsComponent,
    onSubmit,
    onOpenUrl,
    setIsLoaded,
}) => {
    const { block } = useClassnames(SCOPE);
    const { get } = useDataAttributes(SCOPE);
    const { translateKey } = useTranslate();

    if (isLoading) {
        return <LoadingState />;
    }
    if (isNotAuthorized) {
        return (
            <RestrictedAccessState
                description={translateKey(MICRO_APP_CORE.RESTRICTED_ACCESS_DESCRIPTION)}
                shouldShowIcon
                titleAs="h3"
            />
        );
    }

    if (hasError) {
        return <ServiceNotAvailableState />;
    }

    if (!adaptiveCard && !lumappsComponent) {
        return null;
    }

    return (
        <div className={block({ loading: isLoading }, className)} {...get({ element: id })}>
            {lumappsComponent ? (
                <LumappsComponent
                    component={lumappsComponent}
                    onSubmit={(action) => onSubmit({ ...action, actionType: 'lumappsComponent' })}
                />
            ) : (
                adaptiveCard && (
                    <AdaptiveCard
                        onSubmit={(action) => onSubmit({ ...action, actionType: 'adaptiveCard' })}
                        onOpenUrl={onOpenUrl}
                        adaptiveCard={adaptiveCard}
                        openUrlInNewTab
                        setIsLoaded={setIsLoaded}
                    />
                )
            )}
        </div>
    );
};
