import React from 'react';

import { get } from '@lumapps/constants/config';
import { useDispatch } from '@lumapps/redux/react';
import { ANGULAR_SHOW_LINEAR_LOADING_EVENT } from '@lumapps/router/constants';
import { actions as routerActions } from '@lumapps/router/ducks/slice';

import { broadcast } from '../legacy/broadcast';

export enum useLazyLoadingProgressStates {
    initial = 'initial',
    loading = 'loading',
    loaded = 'loaded',
}

type UseLazyLoadingProgressApi = (
    // Whether the component should load. ex: a dialog is opened.
    shouldLoad: boolean,
) => {
    /** The state the loading is currently in */
    state: useLazyLoadingProgressStates;
    // Whether the state is either loading or loaded
    isLoaded: boolean;
    /**
     * Callback when the component is loaded.
     * This should be called by the component on first mount to signal that the code has been successfully downloaded.
     */
    onComponentLoaded(): void;
};

const { isLegacyContext } = get();

/**
 * Hook to use when you want to use the app's linear progress bar as indicator that a component is currently loading.
 * This can be useful for Dialogs for example, to have them be downloaded only when the dialog is opened on the first time.
 *
 * It is used by the `SuspenseWithProgress` component that will automatically do the heavy work for you.
 * If you need to lazy load a component after a specific action and display the app's linear progress bar,
 * you should consider using this component instead of implementing the hook manually.
 *
 * If you need more flexibility, here's a quick example on how you can use this hook manually
 *
 * import { useLazyLoadingProgress } from '../../hooks/useLazyLoadingProgress';
 *
 * const LazyDialog = React.lazy(() => import('./LazyDialog'));
 *
 * const SomePage: React.FC = () => {
 *     const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
 *     const { onComponentLoaded, state, isLoaded } = useLazyLoadingProgress(dialogIsOpen);
 *
 *     return (
 *         <div>
 *             <Button
 *                 type="button"
 *                 onClick={() => setDialogIsOpen(true)}
 *                 disabled={state === useLazyLoadingProgressStates.loading}
 *             >
 *                 Open Dialog
 *             </Button>
 *             {isLoaded && (
 *                 <Suspense fallback={<></>}>
 *                     <LazyDialog isOpen={dialogIsOpen} onOpen={onComponentLoaded}>
 *                         SomeDialog
 *                     </LazyDialog>
 *                 </Suspense>
 *             )}
 *         </div>
 *     );
 * };
 *
 * In this example, the dialog won't actually be downloaded until "isLoaded" is true.
 * The `dialogIsOpen` props is passed as parameter to the `useLazyLoadingProgress` allowing
 * `isLoaded` to be set at true the first time the parameter is set at true.
 *
 * Depending if the hook is used on the legacy or new app, the hook will then either broadcast an angular event or dispatch a redux action
 * to show the loader.
 *
 * To allow the hook to know when the loading is actually complete, the lazy loaded component should call the `onComponentLoaded`
 * callback on first load.
 * This can be done with a simple useEffect, in our case using an `onOpen` prop:
 *
 *     React.useEffect(() => {
 *        if (isOpen && onOpen) {
 *            onOpen();
 *        }
 *    }, [isOpen, onOpen]);
 *
 * Once this callback is called, the component will be set as "loaded" and the progress will not be displayed anymore.
 */
const useLazyLoadingProgress: UseLazyLoadingProgressApi = (shouldLoad) => {
    const dispatch = useDispatch();
    /** The state the loading is currently in */
    const [state, setState] = React.useState<useLazyLoadingProgressStates>(useLazyLoadingProgressStates.initial);

    /**
     * Callback to trigger the display of the progress bar.
     * Will either broadcast the event in legacy app,
     * or dispatch a redux event to the router for the new app.
     */
    const toggleLinearLoader = React.useCallback(
        (showLoader = false) => {
            if (isLegacyContext) {
                broadcast(ANGULAR_SHOW_LINEAR_LOADING_EVENT, showLoader);
            } else {
                dispatch(showLoader ? routerActions.showLoader() : routerActions.hideLoader());
            }
        },
        [dispatch],
    );

    /**
     * Callback when the component is loaded.
     * This should be called by the component on first mount to signal that the code has been successfully downloaded.
     */
    const onComponentLoaded = React.useCallback(() => {
        if (state === useLazyLoadingProgressStates.loading) {
            setState(useLazyLoadingProgressStates.loaded);
            toggleLinearLoader(false);
        }
    }, [state, toggleLinearLoader]);

    React.useEffect(() => {
        /**
         * When nothing has loaded yet and the component that uses the hooks
         * says that it should load, dispatch the event to show the loading
         * and update the state.
         */
        if (state === useLazyLoadingProgressStates.initial && shouldLoad) {
            setState(useLazyLoadingProgressStates.loading);
            toggleLinearLoader(true);
        }
    }, [state, shouldLoad, toggleLinearLoader]);

    // On unmount, remove the linear loader
    React.useEffect(() => {
        return () => {
            toggleLinearLoader(false);
        };
    }, [toggleLinearLoader]);

    return {
        state,
        isLoaded: state !== useLazyLoadingProgressStates.initial,
        onComponentLoaded,
    };
};

export { useLazyLoadingProgress };
