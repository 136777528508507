/* istanbul ignore file */
/** Still in development */
import React, { Suspense } from 'react';

import { ErrorPage } from '@lumapps/lumx-front-office/components/ErrorPage';
import { mdiHome } from '@lumapps/lumx/icons';
import { BrowserRoute, Switch, notFoundRoute, errorRoute, useRouter } from '@lumapps/router';
import { SignInWizard } from '@lumapps/sign-in/components/SignInWizard';
import { signInRoute } from '@lumapps/sign-in/routes';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { LOGIN } from '../../keys';
import { Loading } from '../Loading';

const Pages = () => {
    const { getUrl } = useRouter();
    const loginUrl = getUrl(signInRoute);

    return (
        <ErrorBoundary fallback={<ErrorPage code={500} shouldShowIcon shouldShowAction />}>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <BrowserRoute path={errorRoute.path}>
                        <ErrorPage code={500} shouldShowIcon shouldShowAction />
                    </BrowserRoute>

                    <BrowserRoute path={notFoundRoute.path}>
                        <ErrorPage
                            code={404}
                            shouldShowIcon
                            buttonProps={{ href: loginUrl, leftIcon: mdiHome }}
                            shouldShowAction
                        />
                    </BrowserRoute>

                    <BrowserRoute path={signInRoute.path}>
                        <SignInWizard />
                    </BrowserRoute>

                    <BrowserRoute>
                        <ErrorPage
                            code={404}
                            shouldShowIcon
                            buttonProps={{ href: loginUrl, children: LOGIN.GO_BACK_TO_LOGIN }}
                            shouldShowAction
                        />
                    </BrowserRoute>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export { Pages };
