import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { SIGN_IN } from '@lumapps/sign-in/keys';

import { PASSWORD_MAX_LENGTH } from '../constants';

interface ResetPasswordPageState {
    newPassword: string;
    confirmPassword: string;
    /**  New password error message field  */
    newPasswordErrorMessage: string;
    /**  Confirm password error message field */
    confirmPasswordErrorMessage: string;
    /**  to set a valid field style */
    isValidNewPassword: boolean;
    isValidConfirmPassword: boolean;
    /** Indicates if New password field has an error */
    isNewPasswordError: boolean;
    /** Indicates if Confirm password field has an error */
    isConfirmPasswordError: boolean;
    passwordType: string;
    /** Indicates if the Reset method returns an error from the back  */
    errorReset: boolean;
}
export const initialState: ResetPasswordPageState = {
    newPassword: '',
    confirmPassword: '',
    newPasswordErrorMessage: '',
    confirmPasswordErrorMessage: '',
    isValidNewPassword: false,
    isValidConfirmPassword: false,
    isNewPasswordError: false,
    isConfirmPasswordError: false,
    passwordType: 'password',
    errorReset: false,
};

/** Slice to manage the reset password page state */
export const { actions, reducer } = createSlice({
    domain: 'resetPasswordPage',
    initialState,
    reducers: {
        onChangeNewPassword: (state: ResetPasswordPageState, action: PayloadAction<string>) => {
            const value = action.payload;
            if (value.length < PASSWORD_MAX_LENGTH) {
                set(state, 'isValidNewPassword', false);
                set(state, 'isNewPasswordError', true);
                set(state, 'newPasswordErrorMessage', SIGN_IN.NOT_ENOUGH_CHARACTERS);
            } else if (value.length >= PASSWORD_MAX_LENGTH && state.newPassword !== value) {
                set(state, 'newPasswordErrorMessage', '');
                set(state, 'isValidNewPassword', true);
                set(state, 'confirmPasswordErrorMessage', SIGN_IN.PASSWORD_RESET_DO_NOT_MATCH);
                set(state, 'isValidConfirmPassword', false);
                set(state, 'isConfirmPasswordError', true);
            } else {
                set(state, 'isValidNewPassword', true);
                set(state, 'newPasswordErrorMessage', '');
            }
            set(state, 'newPassword', value);
        },
        changePasswordType: (state: ResetPasswordPageState) => {
            if (state.passwordType === 'password') {
                set(state, 'passwordType', 'text');
                return;
            }
            set(state, 'passwordType', 'password');
        },
        setNewPassword: (state: ResetPasswordPageState, action: PayloadAction<string>) => {
            const newPassword = action.payload;
            set(state, 'newPassword', newPassword);
        },
        setConfirmPassword: (state: ResetPasswordPageState, action: PayloadAction<string>) => {
            const confirmPassword = action.payload;
            set(state, 'confirmPassword', confirmPassword);
        },

        onChangeConfirmPassword: (state: ResetPasswordPageState, action: PayloadAction<string>) => {
            const value = action.payload;
            set(state, 'confirmPassword', value);
            if (state.newPassword !== value) {
                set(state, 'confirmPasswordErrorMessage', SIGN_IN.PASSWORD_RESET_DO_NOT_MATCH);
                set(state, 'isValidConfirmPassword', false);
                set(state, 'isConfirmPasswordError', true);
            } else {
                set(state, 'isValidConfirmPassword', true);
                set(state, 'confirmPasswordErrorMessage', '');
            }
        },
    },
});
