import React from 'react';

import { get as getConfig } from '@lumapps/constants';
import { ThumbnailWithIcon, ThumbnailWithIconProps } from '@lumapps/lumx-images/components/ThumbnailWithIcon';
import { ColorBubbleLetter, ColorBubbleLetterProps } from '@lumapps/lumx-texts/components/ColorBubbleLetter';

import { CustomMediaIconColor } from '../../hooks/useSearchResultIconColor';

const Config = getConfig();

export interface ThumbnailProps extends ThumbnailWithIconProps {
    /** colors to be used for rendering the color bubble component, by default it uses a list of base lumapps colors */
    colors?: ColorBubbleLetterProps['colors'];
    /** Allows to override the color of the icon */
    customColor?: CustomMediaIconColor;
    /** Total number of characters to display when using Color Bubble Letter */
    charactersToDisplay?: number;
    /** The result type of the component. Can be used for customization purposes. */
    resultType?: string;
}

/** Inner part of the thumbnail element of search results */
export const Thumbnail: React.FC<ThumbnailProps> = ({
    icon,
    iconName,
    image,
    colors = Config.defaultColors,
    charactersToDisplay = 1,
    alt,
    className,
    size,
    children,
    ...restOfProps
}) => {
    const isIconValid = Boolean(iconName || icon);

    if (children) {
        return <div className={className}>{children}</div>;
    }

    return (
        <div className={className}>
            {(image || isIconValid) && (
                <ThumbnailWithIcon
                    alt={alt}
                    size={size}
                    icon={iconName || icon}
                    isDynamicIcon={Boolean(iconName)}
                    image={image}
                    {...restOfProps}
                />
            )}

            {!image && !isIconValid && alt && (
                <ColorBubbleLetter colors={colors} text={alt} size={size} maxLength={charactersToDisplay} />
            )}
        </div>
    );
};
