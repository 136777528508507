import { Reducer } from 'react';

// eslint-disable-next-line lumapps/do-not-import-immer
import { setAutoFreeze } from 'immer';
// eslint-disable-next-line lumapps/do-not-import-redux
import { legacy_createStore as createStore, combineReducers, Action } from 'redux';

import { createEnhancer } from './thunks';

/**
 *  With recent updates of immer, the `produce()` function used in all of or reducers return a frozen object.
 *  https://immerjs.github.io/immer/freezing/
 *
 *  Frozen redux store is a very good idea in theory, but in practice we sometimes do not treat the state as immutable
 *  or use shallow copy instead of deep copy before doing data transformations.
 *
 *  For now, we'll disable the auto freezing as the impact is almost impossible to evaluate.
 */
setAutoFreeze(false);

// Define the Reducers that will always be present in the application
let staticReducers = {};
const asyncReducers: any = {};
let store: any;

function createReducer(reducers: Reducer<unknown, Action<any>>) {
    return combineReducers({
        ...staticReducers,
        ...reducers,
    });
}

// Configure the store
export function configureStore(initialState: any, reducers: Reducer<unknown, Action<any>>) {
    staticReducers = reducers;
    store = createStore(createReducer(reducers), initialState, createEnhancer());

    return store;
}

/**
 * Adds a reducer to the store.
 * @param key - key that will be used to save the data on the store
 * @param asyncReducer - reducer to add
 */
export function injectReducer(key: string, asyncReducer: any) {
    asyncReducers[key] = asyncReducer;
    if (store) {
        store.replaceReducer(createReducer(asyncReducers));
    }
}
