import { SIGN_IN } from '@lumapps/sign-in/keys';

/**
 * this function allows to display the error message corresponding to error param
 * @param error error code string
 * @returns matching translation key
 */
export const errorMessage = (error: string) => {
    if (error && SIGN_IN[error as keyof typeof SIGN_IN]) {
        return SIGN_IN[error as keyof typeof SIGN_IN];
    }
    return '';
};
