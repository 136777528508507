/* istanbul ignore file */
/** Still in development */
import React from 'react';

import { initStore, useRetrieveTranslations } from '@apps/core';

import { RouteAnnouncer } from '@lumapps/a11y/components/RouteAnnouncer';
import { ENVS, get } from '@lumapps/constants';
import { Customizations } from '@lumapps/customizations';
import { AdvancedDebugInfo } from '@lumapps/debug';
import { Provider } from '@lumapps/redux/react';
import { BrowserRouter as Router } from '@lumapps/router';
import { ConnectedPageTransition } from '@lumapps/router/components/PageTransition';
import { TranslationsProvider } from '@lumapps/translations';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { rootReducer, transforms, keysToIgnore } from '../../ducks';
import { getBaseUrl } from '../../utils';
import { Loading } from '../Loading';
import { Pages } from '../Pages';

/** Add html attribute on element focus by keyboard (required for LumX focus style). */
import 'focus-visible';

declare const window: any;

const Config = get();

/**
 * Main entrypoint of the loginapplication. It takes care of setting up:
 * - initial state of the application and setting up redux and translations providers
 * - Trigger the different metrics that are needed for the application
 * - Setup the react router implementation
 */
const App = () => {
    const translationsState = useRetrieveTranslations();

    if (!translationsState) {
        return <Loading />;
    }

    window.lumappsPerformances.loaderHidden = Date.now();

    const baseUrl = getBaseUrl(window.location.pathname);
    const store = initStore(rootReducer, {}, transforms, keysToIgnore);

    return (
        <ErrorBoundary fallback={<Loading />}>
            <Provider store={store}>
                <TranslationsProvider value={translationsState}>
                    <ConnectedPageTransition />
                    <Router basename={baseUrl}>
                        <Customizations>
                            <Pages />
                            {Config.environment === ENVS.DEVELOPMENT ? (
                                <AdvancedDebugInfo showModes showFeatureFlags />
                            ) : null}
                        </Customizations>
                        <RouteAnnouncer />
                    </Router>
                </TranslationsProvider>
            </Provider>
        </ErrorBoundary>
    );
};

export { App };
