import React from 'react';

import { useRouter } from '@lumapps/router';
import { focusElement } from '@lumapps/utils/hooks/useFocus';

import { StepProps, UseWizardProps } from '../types';

/**
 * Returns the index for the given step id
 * @param UseWizardProps
 * @returns number
 */
const getStepIndex = <T>({ startingStepId, steps }: Pick<UseWizardProps<T>, 'steps' | 'startingStepId'>) => {
    return steps.findIndex((step) => step.id === startingStepId) || 0;
};

/**
 * Retrieves the starting step index
 * @param UseWizardProps
 * @returns number
 */
const getStartingStep = <T>({ startingStepId, steps, startingStep = 0 }: UseWizardProps<T>) => {
    if (startingStepId) {
        return getStepIndex({ steps, startingStepId });
    }

    return startingStep;
};

/**
 * Hook that allows to control a wizard, with steps that can be navigated by moving towards the previous or next step.
 * @param UseWizardProps
 * @returns UseWizard
 */
export const useWizard = <T>({
    steps,
    startingStep,
    startingStepId,
}: UseWizardProps<T>): Omit<StepProps<T>, 'state' | 'setWizardState'> => {
    const { redirect } = useRouter();
    const initialStep = getStartingStep({ steps, startingStep, startingStepId });
    const focusRef = React.useRef<HTMLDivElement>(null);

    const [currentStep, setCurrentStep] = React.useState(initialStep);

    /**
     * This effect keeps the state up to date when the props change.
     */
    React.useEffect(() => {
        setCurrentStep(initialStep);
    }, [initialStep]);

    /**
     * Moves the wizard to the given step
     */
    const goToStep = React.useCallback(
        (step: number) => {
            if (step < steps.length && step >= 0) {
                setCurrentStep(step);

                const futureStep = steps[step];

                /**
                 * If the wizard has a route, we update execute a redirection
                 * in order to update the URL and allow a navigation between steps.
                 */
                if (futureStep.route) {
                    redirect(futureStep.route());
                }

                focusElement(focusRef);
            }
        },
        [redirect, steps],
    );

    /**
     * Moves the wizard to the next step
     */
    const goToNextStep = () => {
        const nextStep = currentStep + 1;
        goToStep(nextStep);
    };

    /**
     * Moves the wizard to the previous step
     */
    const goToPreviousStep = () => {
        const previousStep = currentStep - 1;
        goToStep(previousStep);
    };

    return {
        goToNextStep,
        goToPreviousStep,
        step: steps[currentStep],
        index: currentStep,
        isLastStep: currentStep === steps.length - 1,
        isFirstStep: currentStep === 0,
        focusRef,
    };
};
