import type { LumappsComponentType } from '../../constants';
import { LumappsComponentInput, LumappsComponentIntegratedBaseProps } from '../../types';
import { LumappsComponentUnavailable } from './LumappsComponentUnavailable';

export interface GetLumappsComponentParams {
    /** The component and its parameters we need to render */
    component: LumappsComponentInput;
    /** The list of integrated components we support */
    choices: Record<LumappsComponentType, React.FC<LumappsComponentIntegratedBaseProps>>;
}

export const getLumappsComponent = ({ component, choices }: GetLumappsComponentParams) => {
    if (!choices[component.type]) {
        return LumappsComponentUnavailable;
    }
    return choices[component.type];
};
