/* eslint-disable default-param-last */
// eslint-disable-next-line lumapps/do-not-import-axios
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';

import { getLumappsPublicUniqueId } from '@lumapps/analytics-tracking/utils';
import { cache, CACHE_TYPE, generateCacheKey } from '@lumapps/cache';
import { get } from '@lumapps/constants';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { getBaseUrl } from '@lumapps/router/utils';
import { actions } from '@lumapps/user/ducks/slice';
import { service } from '@lumapps/user/service';
import { isIE } from '@lumapps/utils/browser/isIE';
import { requestOnIdleCallback } from '@lumapps/utils/function/requestIdleCallback';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import {
    TOKEN_EXPIRATION_THRESHOLD,
    DEFAULT_BASE_PATH,
    HTTP_STATUS_UNAUTHORIZED,
    LUMAPPS_PUBLIC_UNIQUE_ID,
} from '../constants';
import {
    BaseApiInstance,
    BaseApiRequestConfig,
    BaseApiPromise,
    Interceptor,
    PRIORITY,
    REVALIDATED_ON_TYPE,
    BaseApiOptions,
    API_VERSION,
    JWT,
    BaseApiMethods,
} from '../types';
import { decodeToken } from '../utils/decodeToken';
import { DataByEventType, formatStreamResponse } from '../utils/formatStreamResponse';
import { makeParamsSerializer } from '../utils/paramsSerializer';

export const BaseApiHeaders = AxiosHeaders;

const Config = get();

// Store for tracking recent requests
const TIME_THRESHOLD = 100;

/**
 * Base Api class from which all other instances will extend
 */
class BaseApi {
    api: BaseApiInstance;

    baseURL: string;

    path: string | undefined;

    requestConfig: BaseApiRequestConfig;

    validatedValues: Record<string, boolean>;

    /**
     * Stores an AbortController instance for each request set as cancelable.
     * Requests are identified by a key generated using the URL and params.
     */
    abortControllersByRequestKey: Record<string, AbortController>;

    static requestLog: Map<string, number> = new Map();

    static requestLogConfigs: Map<string, InternalAxiosRequestConfig<any>> = new Map();

    static store: any;

    static versions = API_VERSION;

    static interceptors: Interceptor[] = [];

    static refreshTokenPromise: Promise<any> | undefined;

    static latestTokenInfo: JWT | undefined;

    constructor({
        path = '',
        apiVersion = 'v1',
        baseURL = `${DEFAULT_BASE_PATH}`,
        version,
        bypassMonolith = true,
        organizationId = Config.customerId,
        cell = Config.haussmannCell,
        requestConfig = {},
        useShared = false,
    }: BaseApiOptions) {
        const isApiVersion2 = version && version === API_VERSION.v2;
        const hasSpecificBaseUrl = baseURL !== DEFAULT_BASE_PATH;

        /**
         * By default, we consider that this is a v1 endpoint. In that case, we just go ahead and create
         * the base URL with the haussmann cell base path
         */
        let baseUrlToUse = `${DEFAULT_BASE_PATH}/${apiVersion}/${path}`;

        /**
         * If the BaseApi has a defined baseURL, use directly that one and do not perform any changes whatsoever.
         */
        if (hasSpecificBaseUrl) {
            baseUrlToUse = baseURL;
        } else {
            /**
             * When the API is on v2, we need to change the base URL so that it includes the organization id.
             */
            if (isApiVersion2) {
                baseUrlToUse = `${DEFAULT_BASE_PATH}/${
                    API_VERSION.v2 as string
                }/organizations/${organizationId}/${path}`;
            }

            /**
             * If the bypassMonolith flag is on, we need to change the base path to the haussmann cell for both
             * v1 endpoints as well as v2 endpoints.
             */
            if (bypassMonolith) {
                /**
                 * By default, we consider that this is a v1 endpoint. In that case, we just go ahead and create
                 * the base URL with the haussmann cell base path
                 */
                baseUrlToUse = `${cell}/_ah/api/lumsites/${apiVersion}/${path}`;

                /**
                 * Since we are currently migrating several endpoints to the new v2 BaseApi format, some
                 * of them might still be using the `apiVersion` parameter and other might be using the `version` one.
                 * Once we have migrated all of them, this should only be if (isApiVersion2)
                 *
                 * For the ones that are using apiVersion, they already have included the organization ID on their path
                 * so we just need to concatenate the path with the apiVersion and the cell
                 */
                if (apiVersion === 'v2') {
                    baseUrlToUse = `${cell}/${apiVersion}/${path}`;
                }

                /**
                 * For the ones that are using the version parameter, we need to add the organizations slug and the
                 * organization id.
                 */
                if (isApiVersion2) {
                    baseUrlToUse = `${cell}/${API_VERSION.v2 as string}/organizations/${organizationId}/${path}`;
                }
            }
        }

        /**
         * If useShared is true, use the sharedCell
         */
        if (useShared && Config.sharedCell) {
            baseUrlToUse = `${Config.sharedCell}/${path}`;
        }

        this.baseURL = baseUrlToUse;
        this.api = axios.create({ baseURL: baseUrlToUse });
        this.path = path;
        this.requestConfig = requestConfig;
        this.validatedValues = {};

        this.api.interceptors.response.use((response) => response, this.onError);

        BaseApi.interceptors.forEach((interceptor) => {
            this.api.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
        });

        /**
         * Only add this logic in development since it consumes memory and triggers several timeouts.
         */
        if (Config.environment && Config.environment === 'development') {
            /**
             * The following interceptor detects API calls that are executed one after the other within a small
             * timeframe (100ms). The idea is to log errors for duplicated API calls that should not occur, so that
             * we can report them and fix them.
             */
            this.api.interceptors.request.use((config) => {
                const requestKey = `${config.method}:${config.baseURL}:${config.url}:${JSON.stringify(
                    config.params,
                )}:${JSON.stringify(config.data || {})}`;

                const currentTime = Date.now();

                if (BaseApi.requestLog.has(requestKey) && BaseApi.requestLogConfigs.has(requestKey)) {
                    const existingRequest = BaseApi.requestLogConfigs.get(
                        requestKey,
                    ) as InternalAxiosRequestConfig<any>;
                    const lastTime = BaseApi.requestLog.get(requestKey) as number;

                    // Only consider the request duplicated if there was a request within the threshold
                    // and that the previous request was not aborted.
                    if (currentTime - lastTime < TIME_THRESHOLD && !existingRequest.signal?.aborted) {
                        // eslint-disable-next-line no-console
                        console.error(
                            `Duplicate request detected: ${config.method?.toUpperCase()} ${config.baseURL} ${
                                config.url
                            }`,
                        );
                    }
                }

                // Update the request log
                BaseApi.requestLog.set(requestKey, currentTime);
                BaseApi.requestLogConfigs.set(requestKey, config);

                // Optional: Clean up old entries
                setTimeout(() => {
                    BaseApi.requestLog.delete(requestKey);
                    BaseApi.requestLogConfigs.delete(requestKey);
                }, TIME_THRESHOLD);

                return config;
            });
        }

        this.abortControllersByRequestKey = {};
    }

    // eslint-disable-next-line class-methods-use-this
    async onError(err: any) {
        const { response, config: originalRequest } = err;

        // eslint-disable-next-line no-underscore-dangle
        if (response && response.status === HTTP_STATUS_UNAUTHORIZED && !originalRequest._retry) {
            try {
                await BaseApi.refreshToken();

                const { headers } = BaseApi.getBaseConfig();
                // eslint-disable-next-line no-underscore-dangle
                originalRequest._retry = true;
                originalRequest.headers = headers;
                originalRequest.data = originalRequest.data ? JSON.parse(originalRequest.data) : undefined;

                return axios(originalRequest);
            } catch (ignored) {
                throw err;
            }
        }

        throw err;
    }

    static getBaseConfig(baseHeaders?: Record<string, string>): BaseApiRequestConfig {
        const paramsSerializer = makeParamsSerializer({ arrayFormat: 'repeat' });

        const headers: Record<string, string> = {
            'Lumapps-Organization-Id': Config.customerId,
            'Lumapps-Web-Client-Version': Config.frontendVersion,
            'Lumapps-Call-Id': generateUUID(),
            'x-lumapps-analytics': 'on',
            ...baseHeaders,
        };

        const token = service.getUserToken(BaseApi.store?.getState());

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        if (!token) {
            Object.assign(headers, {
                [LUMAPPS_PUBLIC_UNIQUE_ID]: getLumappsPublicUniqueId(),
            });
        }

        if (Config.useDynamicOverrides) {
            Object.assign(headers, {
                'Lumapps-Dynamic-Overrides': window.location.search,
            });
        }

        if (isIE()) {
            Object.assign(headers, {
                /**
                 * IE11 caches GET requests from some reason, this avoids that specific behaviour
                 * https://medium.com/@samichkhachkhi/ie-11-caching-axios-http-calls-e7f6474b11a5
                 * https://stackoverflow.com/questions/45830531/axios-only-called-once-inside-self-invoking-function-internet-explorer/45835054#45835054
                 */
                Pragma: 'no-cache',
            });
        }

        return {
            headers,
            paramsSerializer,
        };
    }

    static addInterceptor(interceptor: Interceptor) {
        BaseApi.interceptors.push(interceptor);
    }

    /**
     * Determines whether the JWT token will expire in the provided threshold
     * @param token - JWT token
     * @param threshold - expiration validity threshold in miliseconds
     * @param baseLine - base line to compare the expiration to, in miliseconds
     * @returns true if the token will expire soon
     */
    static willTokenExpire(
        token: JWT | undefined,
        threshold: number = TOKEN_EXPIRATION_THRESHOLD,
        baseLine?: number,
    ): boolean {
        if (token) {
            const { exp } = token;
            // exp comes in seconds, so we need to convert it into miliseconds
            const expirationTime = exp * 1000;
            const ttl = expirationTime - (baseLine || Date.now());

            return ttl <= threshold;
        }

        return false;
    }

    /**
     * Creates a refresh token promise and sets it on the BaseApi.refreshTokenPromise
     * static variable.
     * @returns refresh token promise
     */
    static refreshToken(): Promise<any> {
        const currentUserToken = service.getUserToken(BaseApi.store?.getState());

        /**
         * If there is already a promise executing, we return that promise directly instead
         * of creating a new one, in order to avoid multiple refresh tokens at the same time.
         */
        if (BaseApi.refreshTokenPromise) {
            return BaseApi.refreshTokenPromise;
        }

        let refreshTokenPromise;

        /**
         * If the BaseApi.store exists, we retrieve the necessary data from it
         */
        if (BaseApi.store) {
            const state = BaseApi.store?.getState();
            const customerId = customerIdSelector(state);
            const baseUrl = getBaseUrl(window.location.pathname, true, true);

            refreshTokenPromise = axios
                .post(`${Config.applicationHost}/service/user/token`, { customerId })
                .then((response) => {
                    /**
                     * If the response is successfull, we retrieve the token and update it
                     * in the store. We also decode it and save it on the BaseApi.latestTokenInfo
                     * variable in order to calculate its validity on each request. We then finally
                     * update the user access token global variable.
                     */
                    if (response.status === 200) {
                        const { token, status } = response.data;

                        /**
                         * If the refresh token call returns an empty token or `status: false`, we just send the user to the login
                         * and let the backend figure out what to do with it.
                         *
                         * We only do that if the user is connected (meaning that there is a token). If this is a public site, we avoid that redirect
                         * and let the user use the page. The feature should not execute any XHR calls if the user is not connected,
                         * but it happens, specially in the legacy app.
                         */
                        if ((token === '' || !status) && currentUserToken) {
                            window.location.href = `${baseUrl}login`;
                        } else {
                            BaseApi.store?.dispatch(actions.setToken(token));
                            BaseApi.latestTokenInfo = decodeToken(token);
                            window.USER_ACCESS_TOKEN = token;
                        }
                    }

                    return response;
                })
                .catch(() => {
                    /**
                     * If there is an error with the refresh token call, we just send the user to the login
                     * and let the backend figure out what to do with it.
                     */
                    window.location.href = `${baseUrl}login`;
                });
        } else {
            refreshTokenPromise = service.refreshToken();
        }

        /**
         * We set the promise on the static variable and then finally, when the
         * promise is finished, we set it to undefined so that the next request that comes
         * in can determine that there is no refresh token in progress and that it can
         * execute the request.
         */
        BaseApi.refreshTokenPromise = refreshTokenPromise;

        return refreshTokenPromise.finally(() => {
            BaseApi.refreshTokenPromise = undefined;
        });
    }

    /**
     * When merging received config to base config,
     * we first need to merge with the instance config
     * and then with the actual base config
     */
    mergeConfig(config: BaseApiRequestConfig): BaseApiRequestConfig {
        const instanceBaseConfig = mergeObjectOnly(cloneDeep(this.requestConfig), config);

        return mergeObjectOnly(cloneDeep(BaseApi.getBaseConfig()), instanceBaseConfig);
    }

    getCompleteUrl(slug: string) {
        return `${this.baseURL}/${slug}`;
    }

    /**
     * Generates an AbortController instance for a request and store it by its identifier (either passed or generated based on url and params).
     * @param url The request url to cancel.
     * @param params The request parameters.
     * @param key The request identifier.
     */
    generateAbortController(url: string, params?: any, key?: string) {
        const requestKey = key || generateCacheKey(this.getCompleteUrl(url), params);

        if (!this.abortControllersByRequestKey[requestKey]) {
            this.abortControllersByRequestKey[requestKey] = new AbortController();
        }

        return this.abortControllersByRequestKey[requestKey];
    }

    /**
     * Cancels a request based either on its identifier or its url and params combined.
     * @param url The request url to cancel.
     * @param params The request parameters.
     * @param key The request identifier.
     */
    cancel(url: string, params?: any, key?: string): void {
        const requestKey = key || generateCacheKey(this.getCompleteUrl(url), params);

        if (this.abortControllersByRequestKey[requestKey]) {
            this.abortControllersByRequestKey[requestKey].abort();

            delete this.abortControllersByRequestKey[requestKey];
        }
    }

    /**
     * Cancels every request for the current BaseApi instance.
     */
    cancelAll(): void {
        forEach(this.abortControllersByRequestKey, (controller: AbortController) => {
            controller.abort();
        });

        this.abortControllersByRequestKey = {};
    }

    /**
     * Centralises all requests in a single method.
     * @param method - HTTP method
     * @param url - URL to request
     * @param config - BaseApiRequestConfig
     * @param data - data to post or put
     * @returns request promise
     */
    request<T = any>(
        method: BaseApiMethods,
        url: string,
        config: BaseApiRequestConfig = {},
        data?: any,
    ): BaseApiPromise<T> {
        /**
         * If the token will expire soon, we create a refresh token request
         * in order to refresh it and avoid any 401.
         */
        if (BaseApi.willTokenExpire(BaseApi.latestTokenInfo)) {
            BaseApi.refreshToken();
        }

        const executeRequest = (updateAccessToken = false) => {
            const requestConfig = { ...config };

            /**
             * if updateAccessToken is true, then it means that this request
             * was blocked due to token expiration. In that scenario, we retrieve
             * the newly generated token in order to execute the request with it
             */
            if (updateAccessToken) {
                const token = service.getUserToken(BaseApi.store?.getState());

                if (token) {
                    Object.assign(requestConfig, {
                        headers: {
                            ...config.headers,
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }
            }

            if (['put', 'post', 'patch'].includes(method)) {
                return this.api[method](url, data, requestConfig);
            }

            return this.api[method](url, requestConfig);
        };

        /**
         * If there is a refresh token in progress, we wait until that request
         * is finished in order to send over the currently requested one. This avoids
         * having unnecessary 401s.
         */
        if (BaseApi.refreshTokenPromise) {
            return BaseApi.refreshTokenPromise.then(() => {
                return executeRequest(true);
            });
        }

        return executeRequest();
    }

    /**
     * The `axios` get method.
     *
     * @param  url The url to request on.
     * @param  config The request config object.
     * @param  applyBaseConfig Whether the base config should be applied or not.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @param  key The request identifier.
     * @return The request promise.
     */
    get<T = any>(
        url: string,
        config: BaseApiRequestConfig = {},
        applyBaseConfig = true,
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = applyBaseConfig ? this.mergeConfig(config) : config;
        const abortController = cancelable ? this.generateAbortController(url, config.params, key) : undefined;

        return this.request('get', url, {
            signal: abortController?.signal,
            ...mergedConfig,
        });
    }

    /**
     * Executes a get operation with the provided priority.
     * @param url The url to request on.
     * @param priority Determines the priority for this api call (defaults to high).
     * @param config The request config object.
     * @param applyBaseConfig Whether the base config should be applied or not.
     * @param cancelable Whether we want this request to be manually cancelable or not.
     * @param key The request identifier.
     */
    getWithPriority<T = any>(
        url: string,
        priority = PRIORITY.HIGH,
        config: BaseApiRequestConfig = {},
        applyBaseConfig = true,
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = applyBaseConfig ? this.mergeConfig(config) : config;
        const abortController = cancelable ? this.generateAbortController(url, config.params, key) : undefined;

        if (priority === PRIORITY.LOW) {
            const lowPriorityPromise: BaseApiPromise<T> = new Promise((resolve, reject) => {
                requestOnIdleCallback(() => {
                    this.request('get', url, {
                        signal: abortController?.signal,
                        ...mergedConfig,
                    })
                        .then(resolve)
                        .catch(reject);
                });
            });

            return lowPriorityPromise;
        }

        return this.request('get', url, {
            signal: abortController?.signal,
            ...mergedConfig,
        });
    }

    /**
     * Executes the provided api call but first, it checks that the value is not already cached.
     * If it is, we retrieve it directly from the cache, if not, we go to the API. This method is really
     * helpful if you are managing API calls where the data does not change over time.
     *
     * @param  url The url to request on.
     * @param  cacheType The type of cache that will be used to save the information.
     * @param  priority Determines the priority for this api call (defaults to high).
     * @param  config The request config object.
     * @param  applyBaseConfig Whether the base config should be applied or not.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @param  requestKey A custom request identifier. If not provided, default to the cache key.
     * @return The request promise.
     */
    getCacheFirst<T = any>(
        url: string,
        cacheType = CACHE_TYPE.MEMORY,
        priority = PRIORITY.HIGH,
        config: BaseApiRequestConfig = {},
        applyBaseConfig = true,
        cancelable = false,
        requestKey: string | undefined = undefined,
    ): BaseApiPromise<T> {
        const key = generateCacheKey(this.getCompleteUrl(url), config.params);
        const cachedValue = cache.retrieve(key, cacheType);

        if (cachedValue) {
            return Promise.resolve({
                data: cachedValue,
                status: 200,
                statusText: 'CACHED_OK',
                headers: new BaseApiHeaders({}),
                config: {
                    headers: new BaseApiHeaders({}),
                },
            });
        }

        return this.getWithPriority(url, priority, config, applyBaseConfig, cancelable, requestKey || key).then(
            (response) => {
                const { data } = response;

                cache.store(key, data, cacheType);

                return response;
            },
        );
    }

    /**
     * Clears any cache stored from the given apiCall and cacheType.
     * If an apiResponse was cached using parameters they will also have to be set.
     * This is useful if you want to clear the cache of an endpoint for future fetches.
     *
     * @param  url The url to request on.
     * @param  cacheType The type of cache that will be used to save the information.
     * @param  params The params used for the initial call.
     */
    clearCache(url: string, cacheType = CACHE_TYPE.MEMORY, params?: BaseApiRequestConfig['params']) {
        const key = generateCacheKey(this.getCompleteUrl(url), params);
        cache.store(key, undefined, cacheType);
    }

    /**
     * Executes the provided api call but first, it checks that the value is not already cached.
     * If it is, we retrieve it directly from the cache, but we schedule an api call to retrieve the latest
     * data for that api call, so the next time it will be retrieved from cache, it will be the latest version.
     * This is useful for data that you want to get as quickly as possible and it is not a big deal that you are
     * one version behind since it rarely changes. Worst case scenario, the next time this api call is executed,
     * you will have the updated values.
     *
     * @param  url    The url to request on.
     * @param  cacheType The type of cache that will be used to save the information.
     * @param  config The request config object.
     * @param  priority Determines the priority for this api call (defaults to high).
     * @param  revalidateOn Determines when this resource should be revalidated.
     *      ONCE means that it will be revalidated one time during the lifetime of the application.
     *      ALWAYS means that it will be revalidated every time this function is called
     * @param  applyBaseConfig Whether the base config should be applied or not.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @return The request promise.
     */
    getStaleWhileRevalidate<T = any>(
        url: string,
        cacheType = CACHE_TYPE.MEMORY,
        config: BaseApiRequestConfig = {},
        priority = PRIORITY.HIGH,
        revalidateOn = REVALIDATED_ON_TYPE.ALWAYS,
        applyBaseConfig = true,
        cancelable = false,
    ): BaseApiPromise<T> {
        const key = generateCacheKey(this.getCompleteUrl(url), config.params);
        const wasValidated = this.validatedValues[key];
        const cachedValue = cache.retrieve(key, cacheType);

        if (cachedValue) {
            if (
                revalidateOn === REVALIDATED_ON_TYPE.ALWAYS ||
                (!wasValidated && revalidateOn === REVALIDATED_ON_TYPE.ONCE)
            ) {
                this.getWithPriority(url, PRIORITY.LOW, config, applyBaseConfig, cancelable, key).then((response) => {
                    const { data } = response;

                    cache.store(key, data, cacheType);

                    return response;
                });
            }

            this.validatedValues[key] = true;

            return Promise.resolve({
                data: cachedValue,
                status: 200,
                statusText: 'CACHED_OK',
                headers: new BaseApiHeaders({}),
                config: {
                    headers: new BaseApiHeaders({}),
                },
            });
        }

        return this.getWithPriority(url, priority, config, applyBaseConfig, cancelable, key).then((response) => {
            const { data } = response;

            cache.store(key, data, cacheType);

            return response;
        });
    }

    /**
     * The `axios` delete method.
     *
     * @param  url    The url to request on.
     * @param  config The request config object.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @param  key The request identifier.
     * @return The request promise.
     */
    delete<T = any>(
        url: string,
        config: BaseApiRequestConfig = {},
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = this.mergeConfig(config);
        const abortController = cancelable ? this.generateAbortController(url, config.params, key) : undefined;

        return this.request('delete', url, {
            signal: abortController?.signal,
            ...mergedConfig,
        });
    }

    /**
     * The `axios` patch method.
     *
     * @param  url    The url to request on.
     * @param  data   The request data.
     * @param  config The request config object.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @param  key The request identifier.
     * @return The request promise.
     */
    patch<T = any>(
        url: string,
        data: any,
        config: BaseApiRequestConfig = {},
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = this.mergeConfig(config);
        const abortController = cancelable
            ? this.generateAbortController(url, { ...data, ...config.params }, key)
            : undefined;

        return this.request(
            'patch',
            url,
            {
                signal: abortController?.signal,
                ...mergedConfig,
            },
            data,
        );
    }

    /**
     * The `axios` post method.
     *
     * @param  url    The url to request on.
     * @param  data   The request data.
     * @param  config The request config object.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @param  key The request identifier.
     * @return The request promise.
     */
    post<T = any>(
        url: string,
        data: any,
        config: BaseApiRequestConfig = {},
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = this.mergeConfig(config);
        const abortController = cancelable
            ? this.generateAbortController(url, { ...data, ...config.params }, key)
            : undefined;

        return this.request(
            'post',
            url,
            {
                signal: abortController?.signal,
                ...mergedConfig,
            },
            data,
        );
    }

    /**
     * Executes a post operation with the provided priority.
     * @param url The url to request on.
     * @param data      The body of the post request.
     * @param priority  The priority that will be used to execute this API call.
     * @param config The request config object, including { priority: PRIORITY } to determine the execution priority.
     * @param applyBaseConfig Whether the base config should be applied or not.
     * @param cancelable Whether we want this request to be manually cancelable or not.
     * @param key The request identifier.
     */
    postWithPriority<T = any>(
        url: string,
        data: any,
        priority = PRIORITY.HIGH,
        config: BaseApiRequestConfig = {},
        applyBaseConfig = true,
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = applyBaseConfig ? this.mergeConfig(config) : config;
        const abortController = cancelable
            ? this.generateAbortController(url, { ...data, ...config.params }, key)
            : undefined;

        if (priority === PRIORITY.LOW) {
            const lowPriorityPromise: BaseApiPromise<T> = new Promise((resolve, reject) => {
                requestOnIdleCallback(() => {
                    this.api
                        .post(url, data, {
                            signal: abortController?.signal,
                            ...mergedConfig,
                        })
                        .then(resolve)
                        .catch(reject);
                });
            });

            return lowPriorityPromise;
        }

        return this.request(
            'post',
            url,
            {
                signal: abortController?.signal,
                ...mergedConfig,
            },
            data,
        );
    }

    /**
     * Executes the provided api call but first, it checks that the value is not already cached.
     * If it is, we retrieve it directly from the cache, if not, we go to the API. This method is really
     * helpful if you are managing API calls where the data does not change over time.
     *
     * @param  url       The url to request on.
     * @param  data      The body of the post request.
     * @param  cacheType The type of cache that will be used to save the information.
     * @param  priority  The priority that will be used to execute this API call.
     * @param  config The request config object.
     * @param  applyBaseConfig Whether the base config should be applied or not.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @return The request promise.
     */
    postCacheFirst<T = any>(
        url: string,
        data: any,
        cacheType = CACHE_TYPE.MEMORY,
        priority = PRIORITY.HIGH,
        config: BaseApiRequestConfig = {},
        applyBaseConfig = true,
        cancelable = false,
    ): BaseApiPromise<T> {
        const cacheKey = generateCacheKey(this.getCompleteUrl(url), { ...data, ...config.params });
        const cachedValue = cache.retrieve(cacheKey, cacheType);

        if (cachedValue) {
            return Promise.resolve({
                data: cachedValue,
                status: 200,
                statusText: 'CACHED_OK',
                headers: new BaseApiHeaders({}),
                config: {
                    headers: new BaseApiHeaders({}),
                },
            });
        }

        return this.postWithPriority(url, data, priority, config, applyBaseConfig, cancelable, cacheKey).then(
            (response) => {
                const { data: responseData } = response;

                cache.store(cacheKey, responseData, cacheType);

                return response;
            },
        );
    }

    /**
     * The `axios` put method.
     *
     * @param  url    The url to request on.
     * @param  data   The request data.
     * @param  config The request config object.
     * @param  cancelable Whether we want this request to be manually cancelable or not.
     * @param  key The request identifier.
     * @return The request promise.
     */
    put<T = any>(
        url: string,
        data?: any,
        config: BaseApiRequestConfig = {},
        cancelable = false,
        key?: string,
    ): BaseApiPromise<T> {
        const mergedConfig = this.mergeConfig(config);
        const abortController = cancelable
            ? this.generateAbortController(url, { ...data, ...config.params }, key)
            : undefined;

        return this.request(
            'put',
            url,
            {
                signal: abortController?.signal,
                ...mergedConfig,
            },
            data,
        );
    }

    /**
     * Same as the `request` method, but with the necessary configuration to fetch an http stream.
     * The stream will then be processed and returned to be consumed.
     * @param method - HTTP method
     * @param url - URL to request
     * @param config - BaseApiRequestConfig
     * @param data - data to post or put
     * @returns async generator
     */
    async stream<T = DataByEventType>(
        method: BaseApiMethods,
        url: string,
        options: BaseApiRequestConfig = {},
        data?: any,
    ) {
        const mergedConfig = this.mergeConfig(options);

        const response = await this.request<ReadableStream<T>>(
            method,
            url,
            {
                adapter: 'fetch',
                responseType: 'stream',
                ...mergedConfig,
            },
            data,
        );

        return formatStreamResponse<T>(response.data);
    }
}

/**
 * We decode the user access token in order to have the necessary information
 * to determine whether the token will expire soon or not. We need to access the token
 * from the window object instead of Config.userAccessToken since the Config object
 * might not yet be initialised before the BaseApi module is required, and therefore
 * the token could be undefined. This way we ensure that the token is always present.
 */
BaseApi.latestTokenInfo = decodeToken(window.USER_ACCESS_TOKEN);

export default BaseApi;
