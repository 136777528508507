/* istanbul ignore file */
/* eslint-disable no-undef */

/**
 * In order to make React Suspense work with a CDN, we need to change the webpack public path
 * on the fly so that we retrieve the chunk from the good URL.
 * https://webpack.js.org/guides/public-path/
 *
 * If we are in production, we need to add a trailing slash to the generated path. In development
 * webpack dev server will take care of it.
 */
// eslint-disable-next-line camelcase
__webpack_public_path__ =
    window.IS_PROD_BUILD === 'true'
        ? `${process.env.PUBLIC_PATH}${process.env.APP_BASE_PATH}/`
        : `${process.env.PUBLIC_PATH}${process.env.APP_BASE_PATH}`;
