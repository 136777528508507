import { DEFAULT_LANGUAGE } from '../constants';
import { getFirstAlternativeLanguage, getContributionLanguages, getInputLanguage } from '../utils';

declare const window: any;

const injectLocale = (initState: any = {}) => {
    const { instance } = initState;
    const currentLang = window.USER_LANG;
    const contributionLangs = getContributionLanguages();
    const fallbackLang = getFirstAlternativeLanguage();
    const intialInputLanguage = getInputLanguage(instance, currentLang, fallbackLang);

    Object.assign(initState, {
        locale: {
            current: currentLang,
            default: DEFAULT_LANGUAGE,
            fallback: fallbackLang,
            inputCustomerLanguage: intialInputLanguage,
            inputLanguage: intialInputLanguage,
            contributionLanguages: contributionLangs,
        },
    });

    return initState;
};

export { injectLocale };
