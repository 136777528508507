import isEmpty from 'lodash/isEmpty';

import { formatSearchResultUrl } from '@lumapps/ask-ai/utils';
import { LUMAPPS_PUBLIC_UNIQUE_ID } from '@lumapps/base-api/constants';
import { cache, CACHE_TYPE } from '@lumapps/cache';
import { findInPersistentStore, storePersistent } from '@lumapps/cache/api/persistentStore';
import { get } from '@lumapps/constants';
import { desktopMinSize } from '@lumapps/responsive/constants';
import { addLocationOriginToUrl } from '@lumapps/router';
import { resultHasTitleAndUrl } from '@lumapps/search-results/utils/resultHasTitleAndUrl';
import { isBaseSearchResult } from '@lumapps/search-results/utils/resultIsBaseSearchResult';
import { SearchResult, SearchResultWithTitleAndUrl } from '@lumapps/search/types';
import { convertDocumentTypeForAnalytics } from '@lumapps/search/utils/convertDocumentTypeForAnalytics';
import { getResultType } from '@lumapps/search/utils/getResultType';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { getCurrentTimezone } from '@lumapps/utils/timezones/getCurrentTimezone';

import { SESSION_ID_CACHE_KEY, SESSION_ID_MAX_DURATION } from './constants';
import { SearchDocumentType, SessionId, TrackingAppType } from './types';

const LumappsConfig = get();

/**
 * Returns an object with a new session id along with a ttl
 */
export const generateSessionId = (): SessionId => ({ id: generateUUID(), ttl: Date.now().toString() });

/**
 * Set session Id in local storage
 */
export const setSessionId = (defaultValue: SessionId) => {
    return cache.store(SESSION_ID_CACHE_KEY, defaultValue, CACHE_TYPE.STORAGE);
};

/**
 * Get session Id from local storage
 */
export const getSessionId = (defaultValue = generateSessionId()): SessionId => {
    return cache.retrieve(SESSION_ID_CACHE_KEY, CACHE_TYPE.STORAGE) || defaultValue;
};

/**
 * set session ID in local storage. If more than 30 minutes have elapsed since the last action or
 * if there is no session ID, a new session id is set. Otherwise we just update the time of the last action.
 */

export const handleSessionId = () => {
    const currentSessionId = getSessionId();
    if (Date.now() - Number(currentSessionId.ttl) > SESSION_ID_MAX_DURATION) {
        setSessionId(generateSessionId());
    } else {
        setSessionId({ id: currentSessionId.id, ttl: Date.now().toString() });
    }
};

export const getEventBaseData = () => {
    // update the session id before doing anything else
    handleSessionId();

    const sessionId = getSessionId();
    return {
        sessionId: sessionId.id,
        performedAt: new Date().toISOString(),
        context: {
            app: {
                version: LumappsConfig.frontendVersion,
                type: window.innerWidth < desktopMinSize ? TrackingAppType.RESPONSIVE : TrackingAppType.DESKTOP,
            },
            screen: {
                width: window.innerWidth.toString(),
                height: window.innerHeight.toString(),
                density: window.devicePixelRatio.toString(),
            },
            page: {
                search: window.location.search,
                path: window.location.pathname,
                title: document.title,
                url: window.location.href,
                referrer: document.referrer,
            },
            timezone: getCurrentTimezone(),
            siteId: LumappsConfig.instanceId,
        },
    };
};

/** Format the given results to match what the analytics api expects. */
export const formatSearchResultsForTrackingAnalytics = (
    results: SearchResult[] = [],
    startPosition = 0,
): SearchResultWithTitleAndUrl[] | undefined => {
    if (!results || isEmpty(results)) {
        return undefined;
    }

    return results.reduce<SearchResultWithTitleAndUrl[]>((acc, curr, i) => {
        /** Save positions before filtering to keep the right ones */
        const position = startPosition + i + 1;

        /** Filters all search results that doesn't have a title and url field */
        if (!resultHasTitleAndUrl(curr)) {
            return acc;
        }

        const { title, id, url } = curr;
        /** Get documentType from metadata/sourceUid field  - if not defined, default to Extension type */
        const type = isBaseSearchResult(curr) ? getResultType(curr) : SearchDocumentType.EXTENSION;

        const formattedResult = {
            title,
            url: addLocationOriginToUrl(formatSearchResultUrl(url)),
            id,
            type: convertDocumentTypeForAnalytics(type),
            position,
        };
        return [...acc, formattedResult];
    }, []);
};

/**
 * Get and set LUMAPPS_PUBLIC_UNIQUE_ID in local storage.
 * It should be used for analytics purpose, for non-connected users.
 * Use localStorage.setItem/localStorage.getItem instead of cache utils
 * Because we do not want the value to be stringified or parsed
 */
export const getLumappsPublicUniqueId = () => findInPersistentStore(LUMAPPS_PUBLIC_UNIQUE_ID);

export const setLumappsPublicUniqueId = () => storePersistent(LUMAPPS_PUBLIC_UNIQUE_ID, generateUUID());
