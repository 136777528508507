/* eslint-disable camelcase */
/* eslint-disable sort-keys */
/* istanbul ignore file */
import { GLOBAL } from '@lumapps/translations';

interface Dictionary<T> {
    [Key: string]: T;
}

// list of provider counting in the IDP quota
const EFFECTIVE_PROVIDER_TYPE: Dictionary<string> = {
    google: 'Google',
    microsoft: 'Microsoft',
    okta: 'Okta',
    saml: 'SAML',
    scim: 'SCIM',
    email: 'LumApps',
};

// exaustive list of available providers
const PROVIDER_TYPE: Dictionary<string> = {
    ...EFFECTIVE_PROVIDER_TYPE,
    batch_provisioning: 'Batch provisioning',
};

const PROVIDER_TABLE_HEADER = [
    {
        isSortable: false,
        label: 'GLOBAL.NAME',
        name: 'name',
        scope: 'col',
    },
    {
        isSortable: false,
        label: 'ADMIN.PROVIDER.TABLE_HEADER_DOMAIN',
        name: 'domain',
        scope: 'col',
    },
    {
        isSortable: false,
        label: 'ADMIN.PROVIDER.TABLE_HEADER_ACTION',
        name: 'action',
        scope: 'col',
    },
    {
        isSortable: false,
        label: 'ADMIN.PROVIDER.TABLE_HEADER_PROVIDER',
        name: 'provider',
        scope: 'col',
    },
    {
        isSortable: false,
        label: 'ADMIN.PROVIDER.TABLE_HEADER_TYPE',
        name: 'type',
        scope: 'col',
    },
    {
        isSortable: false,
        label: GLOBAL.ID,
        name: 'id',
        scope: 'col',
    },
    {
        isSortable: false,
        label: 'ADMIN.PROVIDER.TABLE_HEADER_USERS',
        name: 'users',
        scope: 'col',
    },
    {
        isSortable: false,
        label: 'ADMIN.PROVIDER.TABLE_HEADER_LAST_SYNC',
        name: 'last_sync',
        scope: 'col',
    },
];

const SAML_BUTTON_COLOR = [
    '#FFFFFF',
    '#4BB16B',
    '#EFEFEF',
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#9E9E9E',
    '#607D8B',
    '#000000',
    '#212121',
    '#757575',
    '#BDBDBD',
    '#E0E0E0',
];

// size in bytes, note that this size is hardcoded in the translated string
const SAML_MAX_LOGO_SIZE = 300000;

// Max length of the SAML metadata filename (in char number)
const SAML_MAX_UPLOAD_FILENAME_LENGTH = 20;

const EXCEPTION_FIELD_OPTIONS = {
    text: 'Text',
    bool: 'Boolean',
    exist: 'Exists',
    not_exist: 'Not exists',
    regex: 'Regex exclude empty value',
    regexKeepEmpty: 'Regex keep empty value',
};

const EXCEPTION_FIELD_OPTION_VALUES = {
    True: 'True',
    False: 'False',
};

const DOMAIN_VALIDATION_SLUG = 'provider-verification';

export {
    PROVIDER_TABLE_HEADER,
    PROVIDER_TYPE,
    EFFECTIVE_PROVIDER_TYPE,
    SAML_BUTTON_COLOR,
    SAML_MAX_LOGO_SIZE,
    SAML_MAX_UPLOAD_FILENAME_LENGTH,
    EXCEPTION_FIELD_OPTIONS,
    EXCEPTION_FIELD_OPTION_VALUES,
    DOMAIN_VALIDATION_SLUG,
};

export const obfuscatedOauthClientSecret = Array(12).join('#');

export enum IDENTITY_PROVIDER_TYPES {
    BATCH_PROVISIONING = 'batch_provisioning',
    LOGIN_REDIRECT = 'login_redirect',
    EMAIL = 'email',
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    OKTA = 'okta',
    SAML = 'saml',
    SCIM = 'scim',
    // New providerType to ensure SAML compatibility for the Genesis migration
    SAML_V2 = 'saml_v2',
}

export enum LicenseType {
    TRIAL = 'trial',
    PILOT = 'pilot',
    FULL = 'full',
    RESELLER = 'reseller',
}

export enum TenantTypes {
    google = 'google',
    classic = 'classic',
}

export enum SERVICE_PROVIDER_TYPE {
    SLACK = 'slack',
}
