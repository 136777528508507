import { get } from '@lumapps/constants';

const config = get();

const injectAvailableLanguages = (initState: any = {}) => {
    const { customer } = initState;

    if (customer) {
        Object.assign(initState.customer, {
            availableLanguages:
                (customer.properties && customer.properties.availableLangs) || config.defaultSupportedLanguages,
        });
    }

    return initState;
};

export { injectAvailableLanguages };
