import type { ErrorPageStateProps } from '@lumapps/lumx-states/components/ErrorPageState';
import { ERROR_PAGE as BASE_ERROR_PAGE } from '@lumapps/lumx-states/constants';
import { mdiCompassOffOutline } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

/** ID to set on the main content section */
export const MAIN_CONTENT_ID = 'maincontent';

/** Labels and icons for error pages */
export const ERROR_PAGE: Record<number, Pick<ErrorPageStateProps, 'icon' | 'title' | 'description'>> = {
    ...BASE_ERROR_PAGE,
    404: {
        icon: mdiCompassOffOutline,
        title: GLOBAL.ERROR_PAGE_UNAVAILABLE_TITLE,
        description: GLOBAL.ERROR_PAGE_UNAVAILABLE_DESCRIPTION,
    },
};
