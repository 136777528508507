import castArray from 'lodash/castArray';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';

import { formatLanguageHeader } from '@lumapps/base-api';
import { get } from '@lumapps/constants';
import { getAvailableLanguages } from '@lumapps/customer/ducks/selectors';
import { getInstanceLanguages, getInstanceProperties } from '@lumapps/instance/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import { isConnected } from '@lumapps/user/ducks/selectors';

import { DEFAULT_LANGUAGE } from '../constants';

const constants = get();

const internalLocaleSelector = (state: BaseStore) => state?.locale || {};

/**
 * Retrieves the list of languages from a redux state
 * @param state Redux state
 * @param useCustomerLanguages if set to true, this function will return the languages defined
 * for the current customer. By default, it returns the list of languages defined for the current instance/site
 * @param languagesToInclude if passed in, the languages will be filtered by the list of languages passed in. if this
 * parameter is left empty, then all available languages will be used.
 */
export const getLanguages = (state: BaseStore, useCustomerLanguages = false, languagesToInclude: string[] = []) => {
    let languages = [];

    if (useCustomerLanguages) {
        languages = getAvailableLanguages(state);
    } else {
        languages = getInstanceLanguages(state) || [];
    }

    return languagesToInclude.length > 0 ? intersection(languages, languagesToInclude) : languages;
};

export const localeSelector = createSelector(internalLocaleSelector, (locale) => locale);

export const currentLanguageSelector = createSelector(
    localeSelector,
    (locale) => locale.current || constants.userLang || DEFAULT_LANGUAGE,
);
export const inputLanguageSelector = createSelector(
    localeSelector,
    (locale) => locale.inputLanguage || constants.userLang || DEFAULT_LANGUAGE,
);
export const fallbackLanguageSelector = createSelector(
    localeSelector,
    (locale): string => locale.fallback || constants.userLang || DEFAULT_LANGUAGE,
);
export const contributionLanguagesSelector = createSelector(
    localeSelector,
    (locale) => locale.contributionLanguages || [DEFAULT_LANGUAGE],
);

/**
 * Whether the language switcher should be displayed for the current user
 */
export const isLanguageSwitcherEnabled = createSelector(
    getInstanceProperties,
    isConnected,
    (properties, isUserConnected) => {
        if (!isUserConnected) {
            return true;
        }

        return properties ? properties.displayLanguageSelector : false;
    },
);

/**
 * Return the formatted Accept-Language header, we need it to pass the
 * wanted language in the calls when we want to get some translated content.
 * The format of this header is the following: lang;q=X
 * where X is the weight of the previously mentionned lang, this is used to
 * classify every languages. We can add more than one language, separating them by a comma.
 */
export const getLanguageHeader = createSelector(
    currentLanguageSelector,
    fallbackLanguageSelector,
    (currentLanguage, fallbackLanguage) =>
        formatLanguageHeader(uniq([currentLanguage, ...castArray(fallbackLanguage)])),
);

/**
 * Return the formatted Accept-Language header used to retrieve a content generated by a user.
 * Only get the languages that are set as contribution languages.
 */
export const getAcceptedContributionLanguageHeader = createSelector(
    currentLanguageSelector,
    fallbackLanguageSelector,
    contributionLanguagesSelector,
    (currentLanguage, fallbackLanguage, contributionLanguages) =>
        formatLanguageHeader(
            uniq([currentLanguage, ...castArray(fallbackLanguage)]).filter((lang) =>
                contributionLanguages.includes(lang),
            ),
            false,
        ),
);
