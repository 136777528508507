import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryState {
    /**
     * Whether and error hasBeenCaught or not.
     */
    hasError: boolean;
}

export interface ErrorBoundaryProps {
    children: ReactNode;
    /**
     * The fallback component to show in case of error.
     */
    fallback?: ReactNode;
    /**
     * Callback on error.
     * @return `true` if the error is considered as treated. `false` otherwise (activating the fallback rendering).
     */
    onError?(error: Error, info: ErrorInfo): void | true;
}

/**
 * A component that will catch any error
 * thrown by one of it's children and that either
 * shows the given fallback component, or nothing
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        let hasError = true;
        const { onError } = this.props;
        if (onError) {
            const errorWasTreated = onError(error, info);
            hasError = !errorWasTreated;
        }
        this.setState({ hasError });
    }

    render(): ReactNode {
        const { hasError } = this.state;
        const { children, fallback = null } = this.props;

        return hasError ? fallback : children;
    }
}
