import { ExtensionCategories } from 'lumapps-sdk-js';

import BaseApi, { BaseApiPromise, cleanParams, formatLanguageHeader, PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { get } from '@lumapps/constants';
import { SortersType } from '@lumapps/lumx-filter-and-sort/types';
import type { LumappsComponentType } from '@lumapps/micro-apps-integrations/constants';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { ExtensionStatus } from '../constants';
import {
    ExtensionsAPIProps,
    ExtensionsFilter,
    InstalledExtensionAPIProps,
    InstalledExtensionsAPIProps,
} from '../legacy-types';
import { ExtensionTopic, InstalledExtension, InstalledExtensionTopicsAPIProps } from '../types';

type ExtensionIdParameter = import('../legacy-types').ExtensionIdParameter;

const Config = get();

/**
 * @deprecated This API is deprecated and should not be used anymore.
 * Please use `available-extensions` or `installed-extensions` APIs.
 */
export const organizationExtensionApi = new BaseApi({ path: 'extensions', version: BaseApi.versions.v2 });

/**
 * @deprecated This API is unused here and will be removed from lumapps-web
 */
export const organizationExtensionExternalApi = new BaseApi({
    path: 'extensions/external',
    version: BaseApi.versions.v2,
});

export const organizationInstalledExtensionApi = new BaseApi({
    path: 'installed-extensions',
    version: BaseApi.versions.v2,
});

export const organizationInstalledExtensionsTopicsApi = new BaseApi({
    path: 'installed-extensions-topics',
    version: BaseApi.versions.v2,
});

const languageHeader = {
    'Accept-Language': formatLanguageHeader(
        [...new Set([Config.userLang, (Config.userAlernativeLangs || [])[0], navigator.language])].filter(Boolean),
    ),
};

/**
 * "Get extensions" request parameters.
 */
interface GetExtensionsParams {
    // Key used to cancel a request
    cancelKey?: string;
    // Filter by extension categories
    categories?: ExtensionCategories[];
    // Request cursor
    cursor?: string;
    // Filter by enabled extensions. Deprecated, use statuses = "live"
    enabled?: boolean;
    // Available or Enabled extensions
    filter?: ExtensionsFilter;
    // Filter by installation date (after)
    installedAfter?: string;
    // Filter by installation date (before)
    installedBefore?: string;
    // Max results number
    maxResults?: number;
    // Filter by partner id
    partnerId?: string;
    // Search term
    search?: string;
    // Filter by extension status
    statuses?: ObjectValues<typeof ExtensionStatus>[];
    // Current sort field for the results
    sortOrder?: SortersType['id'];
    // Filter by topics
    topics?: ExtensionTopic[];
    // Filter by lumappsComponents value
    lumappsComponents?: LumappsComponentType[];
}

export type GetInstalledExtensionsParams = Omit<GetExtensionsParams, 'filter'>;

/**
 * Load the paginated list of extensions.
 *
 * @deprecated This API is deprecated and should not be used anymore.
 * Please use `available-extensions` or `installed-extensions` APIs.
 */
export async function fetchExtensions(extensionParams: GetExtensionsParams) {
    const {
        cancelKey,
        categories,
        cursor,
        enabled,
        filter,
        installedAfter,
        installedBefore,
        maxResults = 20,
        partnerId,
        search,
        statuses,
    } = extensionParams;

    const params = {
        categories: categories ? categories.join(',') : undefined,
        cursor,
        enabled,
        filter,
        installedAfter,
        installedBefore,
        maxResults,
        partnerId,
        search,
        statuses: statuses ? statuses.join(',') : undefined,
    };

    return organizationExtensionApi.get<ExtensionsAPIProps>(
        '',
        {
            params: cleanParams(params),
            headers: {
                ...languageHeader,
            },
        },
        true,
        true,
        cancelKey,
    );
}

/**
 * Load the paginated list of the installed extensions.
 *
 * @deprecated Please use the React Query hook.
 */
export function fetchInstalledExtensions(
    extensionParams: GetInstalledExtensionsParams,
    getCacheFirst = false,
    signal: AbortSignal | undefined = undefined,
): BaseApiPromise<InstalledExtensionsAPIProps> {
    const {
        cancelKey,
        categories,
        cursor,
        installedAfter,
        installedBefore,
        maxResults = 20,
        partnerId,
        search,
        sortOrder,
        statuses,
        topics,
        lumappsComponents,
    } = extensionParams;

    const params = {
        categories: categories ? categories.join(',') : undefined,
        cursor,
        installedAfter,
        installedBefore,
        maxResults,
        partnerId,
        search,
        sort: sortOrder,
        statuses: statuses ? statuses.join(',') : undefined,
        topics: topics ? topics.join(',') : undefined,
        lumappsComponents,
    };

    if (getCacheFirst) {
        return organizationInstalledExtensionApi.getCacheFirst(
            '',
            CACHE_TYPE.MEMORY,
            PRIORITY.HIGH,
            {
                params: cleanParams(params),
                headers: {
                    ...languageHeader,
                },
                signal,
            },
            true,
            true,
            cancelKey,
        );
    }
    return organizationInstalledExtensionApi.get(
        '',
        {
            params: cleanParams(params),
            headers: {
                ...languageHeader,
            },
            signal,
        },
        true,
        true,
        cancelKey,
    );
}

/**
 * Fetch an installed extension.
 *
 * @deprecated Please use `installed-extensions` API.
 */
export function fetchInstalledExtension(
    extensionId: InstalledExtensionAPIProps['id'],
): BaseApiPromise<InstalledExtensionAPIProps> {
    return organizationInstalledExtensionApi.get(
        `/${extensionId}`,
        {
            headers: { ...languageHeader },
        },
        true,
        true,
    );
}

/**
 * Fetch a installed extension by it's id.
 *
 * @param {InstalledExtension['id']} extensionId
 * @param {AbortSignal | undefined} signal
 * @returns {Promise<InstalledExtension>}
 */
export const getInstalledExtension = async (
    extensionId: InstalledExtension['id'],
    signal?: AbortSignal,
): Promise<InstalledExtension> => {
    const { data: extension } = await organizationInstalledExtensionApi.get<InstalledExtension>(extensionId, {
        signal,
    });

    return extension;
};

/**
 * Cancel extensions api call
 *
 * @deprecated Please use `installed-extensions` or `available-extensions` APIs.
 * @param  {string}  fetchKey    The call api Key
 * @return {Promise}  Request promise
 */
export const cancelGetExtensions = (fetchKey: string) => {
    return organizationExtensionApi.cancel('', undefined, fetchKey);
};

/**
 * Migrate an extension.
 *
 * @deprecated Please use `installed-extensions` API.
 * @param  {string}  extensionId    Identifier of the extension to migrate.
 * @return {Promise} Migration promise.
 */
export const migrateExtensionApi = ({
    extensionId,
}: ExtensionIdParameter): BaseApiPromise<InstalledExtensionAPIProps> => {
    return organizationExtensionApi.put(extensionId);
};

interface UpdatedInstalledExtensionProps {
    connectorIds?: InstalledExtension['connectorIds'];
    extensionId: ExtensionIdParameter['extensionId'];
    isDebugModeEnabled?: boolean;
    settings: InstalledExtension['configuration'];
    status: ObjectValues<typeof ExtensionStatus>;
    /** List of group ids by which the installed extension of micro-app type should be viewed */
    visibleByGroupIds?: string[];
}

/**
 * Enable/Disable an extension.
 *
 * @deprecated Please use `installed-extensions` API.
 */
export const updatedInstalledExtension = ({
    connectorIds,
    extensionId,
    settings,
    status,
    visibleByGroupIds,
    isDebugModeEnabled,
}: UpdatedInstalledExtensionProps) => {
    const data = {
        status,
        configuration: settings,
        connectorIds,
        ...(visibleByGroupIds !== undefined && { visibleByGroupIds }),
        ...(isDebugModeEnabled !== undefined && { isDebugModeEnabled }),
    };

    return organizationInstalledExtensionApi.put(extensionId, data);
};

/**
 * Uninstall an extension.
 *
 * @deprecated Please use `installed-extensions` API.
 * @param  {string}  extensionId    Identifier of the extension to uninstall.
 * @return {Promise} Request promise.
 */
export const uninstallExtension = ({ extensionId }: ExtensionIdParameter) => {
    return organizationExtensionApi.delete(extensionId);
};

/**
 * Get extension configuration
 *
 * @param  {string}  extensionId    Identifier of the extension to uninstall.
 * @return {Promise} Request promise.
 */
export const getExtensionConfiguration = ({ extensionId }: ExtensionIdParameter) => {
    return organizationExtensionExternalApi.get(`${extensionId}/configurations`);
};

/**
 * Get organization's extensions topics
 *
 * @return {Promise} Request promise.
 */
export const getExtensionsTopics = async (signal?: AbortSignal): Promise<InstalledExtensionTopicsAPIProps> => {
    const { data } = await organizationInstalledExtensionsTopicsApi.get('', { signal });
    return data;
};
