import React, { ReactNode } from 'react';

import { getDefaultInstanceLanguage, getInstanceLanguages } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { TranslationConfig } from '../types';
import { TranslationsContext } from './TranslationsContext';

interface TranslationsProviderProps {
    children: ReactNode;
    value?: TranslationConfig;
}

const TranslationsProvider: React.FC<TranslationsProviderProps> = ({ children, value }) => {
    const instanceLanguages = useSelector(getInstanceLanguages);
    const instanceDefaultLanguage = useSelector(getDefaultInstanceLanguage);
    if (!value) {
        return null;
    }

    return (
        <TranslationsContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                ...value,
                instanceLanguages,
                instanceDefaultLanguage,
                loaded: true,
            }}
        >
            {children}
        </TranslationsContext.Provider>
    );
};

export { TranslationsProvider };
