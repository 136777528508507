import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { FeatureLegacy } from '../types';

const reducers = {
    setFeatures: (state: any, action: PayloadAction<FeatureLegacy[]>): void => {
        action.payload.forEach((feature: FeatureLegacy) => {
            const { name } = feature;
            set(state, name, feature);
        });
    },
};

const slice = createSlice({
    domain: 'features',
    initialState: {},
    reducers,
});

const { actions, reducer } = slice;

export { actions, reducer, reducers };
