import { mdiImageBrokenVariant } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import type { ErrorPageStateProps } from './components/ErrorPageState';

/** Labels and icons for error pages */
export const ERROR_PAGE: Record<number, Pick<ErrorPageStateProps, 'icon' | 'title' | 'description'>> = {
    404: {
        icon: mdiImageBrokenVariant,
        title: GLOBAL.ERROR_PAGE_404_TITLE,
        description: GLOBAL.ERROR_PAGE_404_DESCRIPTION,
    },
    500: {
        icon: mdiImageBrokenVariant,
        title: GLOBAL.ERROR_PAGE_500_TITLE,
        description: GLOBAL.ERROR_PAGE_500_DESCRIPTION,
    },
};
