import React from 'react';

// eslint-disable-next-line lumapps/do-not-import-react-query
import {
    useQuery,
    type UseQueryOptions as BaseUseQueryOptions,
    type UseQueryResult as BaseUseQueryResult,
    type QueryKey as BaseQueryKey,
} from '@tanstack/react-query';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { BaseApiError } from '../types';
import { getStatusFromQuery } from './utils';

export type UseQueryOptions<
    TQueryFnData = any,
    TError = BaseApiError,
    TData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
> = Pick<BaseUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'enabled' | 'cacheTime' | 'staleTime'>;

export type UseQueryResult<TData = unknown, TError = BaseApiError> = BaseUseQueryResult<TData, TError> & {
    /** base loading status for the current query */
    baseLoadingStatus: BaseLoadingStatus;
};

/**
 * React Query Use Query, used when doing API calls to services.
 * Please take a look at the examples on how to implement it.
 *
 * @example
 * import { useQuery } from '@lumapps/base-api/react-query';
 *
 * import { getRunDetail, microAppRunKeys, UseRunDetailParams } from '../../api';
 * import { MicroAppRun } from '../../types';
 *
 * export const useRunDetail = (params: UseRunDetailParams) =>
 *  useQuery<MicroAppRun>({
 *       ...options,
 *       queryKey: microAppRunKeys.detail(params.id, params.extensionId),
 *       queryFn: ({ signal }) => getRunDetail(params.id, params.extensionId, signal),
 *       refetchOnWindowFocus: false,
 *       retry: false,
 *   });
 *
 * @param options
 * @returns UseInfinityQueryResult
 */
export function useCustomQuery<
    TQueryFnData = unknown,
    TError = BaseApiError,
    TData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
>(options: BaseUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> {
    // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
    const query = useQuery(options);

    return React.useMemo(() => {
        // eslint-disable-next-line deprecation/deprecation
        const baseLoadingStatus = getStatusFromQuery(query);

        return {
            ...query,
            baseLoadingStatus,
        };
    }, [query]);
}
