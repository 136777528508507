import { get } from '@lumapps/constants';

const Config = get();

/**
 * Token expiration threshold, determined by the backend
 */
export const TOKEN_EXPIRATION_THRESHOLD = 600000; // 10 minutes in seconds

/**
 * Status code for unauthorized access
 */
export const HTTP_STATUS_UNAUTHORIZED = 401;

/**
 * Default lumapps services base path
 */
export const DEFAULT_BASE_PATH = `${Config.applicationHost}/_ah/api/lumsites`;

/**
 * Regex used to extract information from a stream chunk.
 * Each chunk is a string that is built as such:
 *
 * event:[eventName]
 * data: [dataObject]
 *
 * and this regex will extract both the event and the data object.
 */
export const EXTRACT_STREAMING_CHUNK_REGEX = /(?:event:(\w+)\s+)?data:\s*({.*?}$)/gm;

/** Header set for public users, for analytics purpose */
export const LUMAPPS_PUBLIC_UNIQUE_ID = 'LUMAPPS-PUBLIC-UNIQUE-ID';
