import { LumappsComponentType } from '../../constants';
import { LumappsComponentIntegratedBaseProps } from '../../types';
import { UploadMediaIntegrated } from '../UploadMediaIntegrated';
import { LumappsComponentUnavailable } from './LumappsComponentUnavailable';

/** Mapped list of components the Lumapps Components feature currently supports */
export const components: Record<LumappsComponentType, React.FC<LumappsComponentIntegratedBaseProps>> = {
    uploader: UploadMediaIntegrated,
    file_list: LumappsComponentUnavailable,
    ai_augmented_search: LumappsComponentUnavailable,
};
