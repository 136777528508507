export enum RESET_PASSWORD {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191806963
     */
    TRY_ANOTHER_ADDRESS = 'TRY_ANOTHER_ADDRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191807242
     */
    EMAIL_NOT_RECEIVED = 'EMAIL_NOT_RECEIVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191807399
     */
    LINK_HAS_BEEN_SENT_TO = 'LINK_HAS_BEEN_SENT_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210141044
     */
    LOGIN_RESET_LINK_TEXT = 'LOGIN.RESET_LINK_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210141048
     */
    LOGIN_RESET_LINK_BUTTON = 'LOGIN.RESET_LINK_BUTTON',
}
