/* eslint-disable lumapps/no-manual-bems */
import React, { ReactElement } from 'react';

import { classnames } from '@lumapps/classnames';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import {
    AspectRatio,
    Badge,
    BadgeWrapper,
    ColorPalette,
    Icon,
    IconProps,
    IconSizes,
    Size,
    Thumbnail,
    ThumbnailProps,
    ThumbnailSize,
    ThumbnailVariant,
    Tooltip,
} from '@lumapps/lumx/react';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import './index.scss';

export interface ThumbnailWithIconProps extends Partial<ThumbnailProps>, Partial<IconProps> {
    /** custom classname for the thumbnail */
    className?: string;
    /** className to display when the thumbnail is displayed */
    imageClassname?: string;
    /** className to display only when the icon is displayed */
    iconClassName?: string;
    /** whether the provided icon is dynamic or not */
    isDynamicIcon?: boolean;
    /**
     * badge to display on top of thumbnail, warning: use when there is only an icon (no image)
     * if there is an image it will override the icon
     */
    badge?: ReactElement;
    /** is fully rounded? */
    isRounded?: boolean;
}

const CLASSNAME = 'thumbnail-with-icon';

/**
 * Component that displays a thumbnail, usually representing an entity, with a small icon on the
 * right bottom side of it. Used for representing entities that have a thumbnail, while adding
 * an icon showing what type of entity it is.
 *
 * @family Images
 * @param props ThumbnailWithIconProps
 * @returns ThumbnailWithIcon
 */
export const ThumbnailWithIcon: React.FC<ThumbnailWithIconProps> = ({
    icon,
    image,
    size = Size.m,
    hasShape = true,
    alt,
    aspectRatio = AspectRatio.square,
    isRounded = false,
    imageClassName: propsImageClassName,
    iconClassName: propsIconClassName,
    isDynamicIcon = false,
    className,
    badge,
    ...forwardedProps
}) => {
    const { iconPath } = useDynamicIcon({ icon: isDynamicIcon ? icon : undefined });

    if (!icon && !image) {
        return null;
    }

    const iconPathToUse = isDynamicIcon ? iconPath : icon;

    const defaultClassNames = classnames(CLASSNAME, className, `${CLASSNAME}--${size}`);

    const imageClassName = classnames(defaultClassNames, `${CLASSNAME}--img`, propsImageClassName, {
        [`${CLASSNAME}--img-rounded`]: isRounded,
    });

    const iconClassName = classnames(defaultClassNames, `${CLASSNAME}--icon`, propsIconClassName);

    const renderInner = () => {
        if (image) {
            return (
                <Thumbnail
                    image={image}
                    size={size as ThumbnailSize}
                    className={imageClassName}
                    alt={alt || ''}
                    variant={ThumbnailVariant.rounded}
                    aspectRatio={aspectRatio}
                    badge={
                        badge ??
                        (iconPathToUse && (
                            <Badge color={ColorPalette.grey}>
                                <Icon icon={iconPathToUse} />
                            </Badge>
                        ))
                    }
                    imgProps={{
                        width: REAL_SIZE_FOR_LUMX_SIZE[size],
                        height: REAL_SIZE_FOR_LUMX_SIZE[size],
                    }}
                    {...forwardedProps}
                />
            );
        }

        if (badge) {
            return (
                <BadgeWrapper badge={badge}>
                    <Icon
                        className={iconClassName}
                        icon={iconPathToUse as string}
                        hasShape={hasShape}
                        size={size as IconSizes}
                        {...forwardedProps}
                    />
                </BadgeWrapper>
            );
        }

        return (
            <Icon
                className={iconClassName}
                icon={iconPathToUse as string}
                hasShape={hasShape}
                size={size as IconSizes}
                {...forwardedProps}
            />
        );
    };

    return <Tooltip label={alt}>{renderInner()}</Tooltip>;
};

ThumbnailWithIcon.displayName = 'ThumbnailWithIcon';
