/* istanbul ignore file */
import React from 'react';

import { renderApplication } from '@apps/core/render';
import ReactDOM from 'react-dom';
import '@apps/core/utils/publicPath';

// Must import base style (LumX) before react component CSS.
import './index.scss';

import { App } from './components/App';

// https://lumapps.atlassian.net/browse/IAM-1015
// must clear the init service cache when logging in.
// allows to remove old session data
// otherwise the logged-out users stays connected until cache is refreshed.
if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('serviceInitCache');
}

renderApplication({
    render: () => {
        ReactDOM.render(<App />, document.getElementById('login-app'));
    },
});
