import 'intersection-observer';

export interface RenderApplicationOptions {
    render: () => void;
}

export const renderApplication = (options: RenderApplicationOptions) => {
    const { render } = options;
    const renderPromises: Promise<any>[] = [];

    Promise.all(renderPromises).then(render);
};
