import React, { useState } from 'react';

import { spacing, useClassnames } from '@lumapps/classnames';
import { errorMessage } from '@lumapps/login/utils/displayErrorMessage';
import { ReCaptcha } from '@lumapps/lumx-inputs/components/Captcha';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { Button, Kind, Message, Text, TextField } from '@lumapps/lumx/react';
import { useQueryParams } from '@lumapps/router';
import { SIGN_IN } from '@lumapps/sign-in/keys';
import { useTranslate } from '@lumapps/translations';
import { isValidEmail } from '@lumapps/utils/string/isValidEmail';

import { RENEW_PASSWORD_FORM_ID, TITLE_ID } from '../../constants';
import { RESET_PASSWORD } from '../../keys';

import './index.scss';

const CLASSNAME = 'renew-password-form';
export interface RenewPasswordForm extends StepProps {
    /** callback to set the current wizard state */
    setWizardState: (state: any) => void;
}
export const RenewPasswordForm: React.FC<RenewPasswordForm> = ({ setWizardState }) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const [email, setEmail] = useState<string>('');
    const [captcha, setCaptcha] = useState<string>('');
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorEmail, setErrorEmail] = useState<string>('');
    const { error, correlationId } = useQueryParams();
    const { block, element } = useClassnames(CLASSNAME);

    /**
     * Trigger when the captcha change.
     *
     * @param {string} value The response of the captcha.
     */
    function onChange(value: string) {
        setCaptcha(value);
    }

    /**
     * Return error, if email haven't a valid format
     *
     */
    const controlEmailAddress = (value: string) => {
        if (!isValidEmail(value)) {
            setErrorEmail(translateKey(SIGN_IN.INVALID_EMAIL_ERROR));
            setHasError(true);
        } else {
            setErrorEmail('');
            setHasError(false);
        }
    };

    function onChangeEmail(value: string) {
        setEmail(value);
        controlEmailAddress(value);
        setWizardState({ email: value });
    }
    /**
     * Since we are bound to the current login endpoint
     * and it doesn't accept the ReCaptchaRef attribute in the payload
     * we need to move it out of the form. And since
     * the TextField needs to be above the ReCaptcha, we also set it out of the form.
     * TODO: Move both fields back inside the form once the backend manages ReCaptcha value.
     */
    return (
        <>
            <div className={block()}>
                <TextField
                    form={RENEW_PASSWORD_FORM_ID}
                    onChange={onChangeEmail}
                    className={element('input')}
                    label={translateKey(SIGN_IN.EMAIL_ADDRESS)}
                    error={errorEmail}
                    hasError={hasError}
                    value={email}
                />

                <ReCaptcha form={RENEW_PASSWORD_FORM_ID} className={element('re-captcha')} handleChange={onChange} />
            </div>

            <form
                id={RENEW_PASSWORD_FORM_ID}
                aria-labelledby={TITLE_ID}
                method="POST"
                className={block()}
                action={window.location.pathname}
            >
                <input id="email" type="hidden" name="email" value={email} />

                <input id="captcha" type="hidden" name="captcha" value={captcha} />

                <Button
                    disabled={captcha === '' || !isValidEmail(email)}
                    className={element('button')}
                    type="submit"
                    fullWidth
                >
                    {translateKey(RESET_PASSWORD.LOGIN_RESET_LINK_BUTTON)}
                </Button>

                {errorMessage(error) && (
                    <Message className={spacing('margin', 'top', 'big')} kind={Kind.error} hasBackground>
                        <Text as="p"> {translateKey(errorMessage(error))} </Text>

                        {correlationId && (
                            <Text as="p">
                                {translateAndReplace(SIGN_IN.GENERIC_ERROR, {
                                    CORRELATION_ID: correlationId,
                                })}
                            </Text>
                        )}
                    </Message>
                )}
            </form>
        </>
    );
};
