import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { CustomerMobileLoginConfiguration, Customer } from '../types';

// This initial state is empty as it's filled directly by the service/init in the legacy code
const initialState = {};

export const reducers = {
    updateMobileLoginConfiguration(
        state: any,
        { payload: { linkDocPasswordRecover, linkDocHowToLogin } }: PayloadAction<CustomerMobileLoginConfiguration>,
    ) {
        set(state.mobileLoginConfiguration, 'linkDocPasswordRecover', linkDocPasswordRecover);
        set(state.mobileLoginConfiguration, 'linkDocHowToLogin', linkDocHowToLogin);
    },
    updateMobileGeneralSettings(
        state: Customer,
        {
            payload: { mobileInstanceKeys, mobileSearchInstanceKeys },
        }: PayloadAction<{
            mobileInstanceKeys: Customer['mobileInstanceKeys'];
            mobileSearchInstanceKeys: Customer['mobileSearchInstanceKeys'];
        }>,
    ) {
        set(state, 'mobileInstanceKeys', mobileInstanceKeys);
        set(state, 'mobileSearchInstanceKeys', mobileSearchInstanceKeys);
    },
    updateCustomer(state: Customer, action: PayloadAction<Customer>) {
        Object.assign(state, action.payload);
    },
    resetCustomer(state: Record<string, Customer>) {
        // eslint-disable-next-line no-param-reassign
        Object.keys(state).forEach((key) => delete state[key]);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'customer',
    initialState,
    reducers,
});
