import React from 'react';

import { LoginPage } from '@lumapps/login/components/LoginPage';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { SIGN_IN } from '@lumapps/sign-in/keys';
import { useTranslate } from '@lumapps/translations';

import { RESET_PASSWORD } from '../../keys';
import { RenewPasswordForm } from '../RenewPasswordForm';

/**  RenewPasswordStep: Component which render the step of Renew password
 * where user can fill his email and valid captcha in order to receive the reset link via email */
export const RenewPasswordStep: React.FC<StepProps> = ({ backButton, ...props }) => {
    const { translateKey } = useTranslate();
    return (
        <LoginPage
            title={translateKey(SIGN_IN.FORGOT_YOUR_PASSWORD)}
            subtitle={translateKey(RESET_PASSWORD.LOGIN_RESET_LINK_TEXT)}
            before={backButton}
            displayPoweredBy
        >
            <RenewPasswordForm {...props} />
        </LoginPage>
    );
};
