import curry from 'lodash/fp/curry';

type DiffGranularity = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days';

/** Map of dividing number used to format timestamps based on a specified granularity */
const TIMESTAMP_GRANULARITY_MAP: Record<DiffGranularity, number> = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
};

/**
 * Get the time difference between two dates with a specified granularity.
 * */
const getDatesDifference = (diffType: DiffGranularity, first: Date, last: Date): number => {
    const diffTime = Math.abs(last.getTime() - first.getTime());

    return Math.floor(diffTime / TIMESTAMP_GRANULARITY_MAP[diffType]);
};

export const getDifferenceInMilliseconds = curry(getDatesDifference)('milliseconds');
export const getDifferenceInSeconds = curry(getDatesDifference)('seconds');
export const getDifferenceInMinutes = curry(getDatesDifference)('minutes');
export const getDifferenceInHours = curry(getDatesDifference)('hours');
export const getDifferenceInDays = curry(getDatesDifference)('days');
