/** istanbul ignore file */
// TODO: ADD UNIT TESTS AND REMOVE IGNORE
import React from 'react';

import loDebounce from 'lodash/debounce';
import isArray from 'lodash/isArray';

import { isCancel, BaseApiPromise, BaseApiError } from '@lumapps/base-api';
import { DEBOUNCE_DELAY } from '@lumapps/constants';

import { BaseLoadingStatus } from '../types/BaseLoadingStatus';
import { actions, reducer, initialState } from './useFetchWithStatusSlice';

export type FetchParams = any | any[];
export type FetchCallbackParams<ApiResponseType = any> =
    | {
          // Whether the fetch was successful.
          success: true;
          // The response sent from the api
          response?: ApiResponseType;
      }
    | {
          // Whether the fetch was successful.
          success: false;
          // The response sent from the api
          response?: any;
      };

export interface CancelFetchProps {
    /**
     * The params to pass to the cancel function set on `onFetchCancel` prop.
     * Can either be an array or a single entity.
     * Ex:
     *
     * If your cancel function uses a single parameter like this: cancelList(fetchKey?: string);
     *
     * You can call it like this:
     * cancel({ params: myFetchKey });
     *
     * On the other hand, it is uses multiple paremeter, like this: cancelList(params?: ParamType, fetchKey?: string);
     * You can use an array to pass multiple parameters
     * cancel({ params: [myParameters, myFetchKey] });
     * */
    params?: FetchParams;
    /**
     * Canceling a fetch will set the status to "idle" by default.
     * Use this to keep the current state (while debouncing for example)
     * */
    keepCurrentState?: boolean;
}

export interface FetchPropsWithoutCallback {
    /**
     * The parameter to use for the fetch.
     * Can either be an array to spread to the function or
     * any single value.
     */
    params?: FetchParams;
    /** Whether the debounce should be use for this fetch. */
    debounce?: boolean;
    /** Whether the call is used to fetch more data. Will change the status to loadingMore. */
    fetchMore?: boolean;
    /** Whether the fetch is done in the background or not. This mean the status will not be updated */
    backgroundFetch?: boolean;
}

export type FetchCallback<ApiResponseType> = (
    params: FetchCallbackParams<ApiResponseType>,
    fetchParams: FetchPropsWithoutCallback,
) => void;

/** The props of the fetch function */
export interface FetchProps<ApiResponseType = any> extends FetchPropsWithoutCallback {
    /** optional callback to execute after a fetch. */
    callback?: FetchCallback<ApiResponseType>;
}

/**
 * Return values of the hook.
 */
export interface UseFetchPropsReturn<ApiResponseType> {
    /** The current fetch status. */
    status: BaseLoadingStatus;
    /** The last fetch response, if any. */
    response?: ApiResponseType;
    /** The last fetch response, if any. */
    errorResponse?: any;
    /** The last used fetch parameters. */
    fetchParams: FetchParams;
    /** callback to reset internal state */
    reset: () => void;
    /** manually update the response in case the fetch is used for edition */
    update: (response: ApiResponseType) => void;
    /** Callback to trigger the actual fetch with given parameters. */
    fetch(params?: FetchProps<ApiResponseType>): void;
    /** Callback to manually cancel ongoing fetch. */
    cancelFetch(params?: CancelFetchProps): void;
}

// Input props of the hook.
export interface UseFetchProps<ApiResponseType> {
    /**
     * The function to call when a fetch is triggered.
     * */
    onFetch?(...params: any[]): BaseApiPromise<ApiResponseType>;

    /** The function to call when a fetch should be cancelled. */
    onFetchCancel?(...params: any[]): void;

    /**
     * Whether a fetch should be triggered on mount.
     * If at false, fetch should be done using the fetch function.
     * */
    fetchOnMount?: boolean;
    /** The params to use if fetchOnMount is set as true. */
    initialFetchParams?: FetchProps<ApiResponseType>;
    /**
     * The debounce delay when fetch are debounced.
     *
     * If undefined, the DEBOUNCE_DELAY lumapps constant will be used.
     */
    debounceDelay?: number;
}

/**
 * Hook to use to fetch an api while tracking it's status.
 *
 * This hook can be useful if you need call an API and manage
 * it's state, by showing a loading or an error message for example.
 *
 * Ex:
 *
 * const { fetch, status, response } = useFetchWithStatus<CalendarListResponse>({
 *    onFetch: getCalendarList,
 *    onFetchCancel: cancelList,
 * });
 *
 * return (
 *   <div>
 *       <Button onClick={() => fetch({ params: { maxResults: 50 } })}>Fetch</Button>
 *       {status === BaseLoadingStatus.loading ? (
 *           'Loading'
 *       ) : (
 *           <ul>{response && response?.items.map((item) => <li key={item.id}>{item.label}</li>)}</ul>
 *       )}
 *   </div>
 * );
 *
 * See stories for more examples.
 * @deprecated please use React Query's `useQuery`
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useFetchWithStatus = <ApiResponseType extends any = any>({
    onFetch,
    onFetchCancel,
    initialFetchParams,
    fetchOnMount,
    debounceDelay = DEBOUNCE_DELAY,
}: UseFetchProps<ApiResponseType> = {}): UseFetchPropsReturn<ApiResponseType> => {
    // Initialize reducer
    const [state, dispatch] = React.useReducer(reducer, initialState);
    // Cancel function
    const cancelFetch = React.useCallback(
        ({ params, keepCurrentState }: CancelFetchProps = {}) => {
            const funcParams = isArray(params) ? params : [params];

            if (onFetchCancel) {
                onFetchCancel(...funcParams);
                if (!keepCurrentState) {
                    dispatch(actions.setStatus(BaseLoadingStatus.idle));
                }
            }
        },
        [onFetchCancel],
    );

    /**
     * Fetch using given params.
     */
    const triggerFetch = React.useCallback(
        async (fetchParams: FetchProps<ApiResponseType>) => {
            const { callback, ...fetchParamsWithoutCallback } = fetchParams;
            const { params, fetchMore, backgroundFetch } = fetchParamsWithoutCallback;
            if (!onFetch) {
                return;
            }

            // Convert params into array if not the case.
            const funcParams = isArray(params) ? params : [params];
            const fetchAction = fetchMore ? actions.fetchMore : actions.fetch;

            try {
                // Dispatch the action to keep the reducer up to date.
                dispatch(fetchAction({ ...params, backgroundFetch }));

                // Do actual fetch.
                const { data } = await onFetch(...funcParams);

                if (callback) {
                    callback({ success: true, response: data }, fetchParamsWithoutCallback);
                }

                // Store response.
                dispatch(actions.onFetchSuccess(data));
            } catch (exception) {
                if (exception instanceof BaseApiError) {
                    if (isCancel(exception)) {
                        return;
                    }

                    const { response } = exception;

                    if (callback) {
                        callback({ success: false, response }, fetchParamsWithoutCallback);
                    }

                    // Set as error and store the response.
                    dispatch(actions.onFetchFailure(response));
                }
            }
        },
        [onFetch],
    );

    // Debounced fetch query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetch = React.useCallback(loDebounce(triggerFetch, debounceDelay), []);

    /**
     * Fetch using given parameters
     * Can be either immediate or debounced.
     * */
    const fetch = React.useCallback(
        (fetchProps: FetchProps<ApiResponseType> = {}) => {
            const { debounce } = fetchProps;

            if (debounce) {
                dispatch(actions.setStatus(BaseLoadingStatus.debouncing));
                debouncedFetch(fetchProps);
            } else {
                triggerFetch(fetchProps);
            }
        },
        [debouncedFetch, triggerFetch],
    );

    /**
     * If no load has been done yet and fetchOnMount is set as true,
     * trigger a fetch.
     */
    React.useEffect(() => {
        if (state.status === BaseLoadingStatus.initial && onFetch && fetchOnMount) {
            fetch(initialFetchParams);
        }
    }, [fetch, fetchOnMount, initialFetchParams, onFetch, state.fetchParams, state.status]);

    return {
        ...state,
        fetch,
        cancelFetch,
        fetchParams: state.fetchParams,
        reset: () => {
            dispatch(actions.reset());
        },
        update: (response: ApiResponseType) => {
            dispatch(actions.updateResponse(response));
        },
    };
};
