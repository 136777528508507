import { IDENTITY_PROVIDER_TYPES } from '@lumapps/reseller-console/types';

export const COMPACT_LOGIN_LIST_LENGTH = 15;

export interface LogoutUrlProps {
    url: string;
}
export const LOGOUT_URL: Record<any, LogoutUrlProps> = {
    [IDENTITY_PROVIDER_TYPES.GOOGLE]: {
        url: 'https://accounts.google.com/logout',
    },
    [IDENTITY_PROVIDER_TYPES.MICROSOFT]: {
        url: 'https://login.microsoftonline.com/logout.srf',
    },
};
