/* istanbul ignore file */
import React from 'react';

import { useHistory } from 'react-router-dom';

import { get as getConstants } from '@lumapps/constants';
import { useDispatch, useStore } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';

import { RouterApi } from '../api';
import { AppId } from '../constants';
import { isRedirectionBlocked } from '../ducks/selectors';
import { actions as routerActions } from '../ducks/slice';
import { webviewRouterApi, Context, routerApi, angularApi } from '../routers';
import { createUrl, isAdminUrl } from '../utils';

const { isLegacyContext, applicationId } = getConstants();

/* istanbul ignore next */
export const useRouter = (): RouterApi => {
    const history = useHistory();
    const currentContext = React.useContext(Context);
    const store = useStore<BaseStore>();

    const dispatch = useDispatch();

    const redirect = React.useMemo<RouterApi['redirect']>(() => {
        // Angular router
        if (isLegacyContext) {
            return angularApi.redirect;
        }
        // Webview app router.
        if (applicationId === AppId.webview) {
            return webviewRouterApi.redirect;
        }

        // React router
        return (route, { routerData, openInNewTab, replace, keepFocus, ignoreBlockedRedirection } = {}) => {
            const currentState = store.getState();
            const isRoutingBlocked = isRedirectionBlocked(currentState);
            // On redirect, store current location into router state
            const routeWithPreviousPath = {
                ...route,
                state: {
                    previousPath: history?.location?.pathname,
                    ...route.state,
                },
            };

            // Store router data.
            if (routerData) {
                dispatch(routerActions.setData(routerData));
            }

            if (!openInNewTab && isRoutingBlocked && !ignoreBlockedRedirection) {
                // If the routing is blocked, we must set the redirection information in order
                // to continue the redirection if the user unblocks it.
                // Once the route is set, the BlockedRedirectionDialog will open.
                dispatch(routerActions.setBlockedRedirection({ route, history }));
            } else {
                routerApi.redirect(routeWithPreviousPath, history, { openInNewTab, replace, keepFocus });

                /**
                 * If the redirection block was ignore, propagate that on redux so that the next navigation works
                 * as expected
                 */
                if (ignoreBlockedRedirection) {
                    dispatch(routerActions.unblockRedirection());
                }
            }
        };
    }, [dispatch, history, store]);

    const currentUrl = window.location.pathname;

    return {
        getUrl: createUrl,
        redirect,
        context: {
            ...currentContext,
            isBackOffice: isAdminUrl(currentUrl),
        },
    };
};
