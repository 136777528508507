import { STEPS } from '@lumapps/sign-in/routes';

/**
 * Sanitize the URL used to POST the login forms.
 * The React router add a <step> path to the current URL to load the correct
 * component. This extra path is not needed in the API call made through the POST
 * form.
 * Depending on the STEP we are, this method truncate the step part of the URL.
 *
 * @param pathname current pathname (window.location.pathname)
 * @param queryString potential search path in the current URL (window.location.search)
 * @returns the sanitize version of the endpoint ready to be used in a login form
 */
export const sanitizeLoginStepFormUrl = (loginStep: STEPS, pathname: string, queryString: string = '') => {
    return `${pathname.replace(`/${loginStep}`, '')}${queryString}`;
};
