import DOMPurify, { Config } from 'dompurify';

/** Custom params not provided by DOMPurify. */
interface CustomParams {
    /** Wrap `<script>` and `<style>` elements in `<pre><code>` to disable them but still show them. */
    debugScriptsAndStyles?: boolean;
}

export interface SanitizeHTMLParams extends CustomParams {
    /** html allowed tag, like p or a (overridden by {@link useProfiles}) */
    allowedTags?: string[];
    /** html allowed attributes, like style or class (overridden by {@link useProfiles}) */
    allowedAttr?: string[];
    /** allow all tags for a profile (html, svg, mathMl) */
    useProfiles?: Config['USE_PROFILES'];
    /** html forbidden tag, like p or a (great to use with {@link useProfiles} and make it stricter) */
    forbidTags?: string[];
    /** html forbidden attributes, like style or class (great to use with {@link useProfiles} and make it stricter) */
    forbidAttr?: string[];
    /** Add html tag, like p or a (on top of the profile defined in {@link useProfiles}) */
    addTags?: string[];
    /** Add attributes, like style or class (on top of the profile defined in {@link useProfiles}) */
    addAttr?: string[];
}

/**
 * @param dirty the html string to clean
 * @param params See {@link SanitizeHTMLParams}
 * @returns cleaned html string
 */
export const sanitizeHTML = (
    dirty: string | null | undefined,
    {
        allowedTags = [],
        allowedAttr = [],
        useProfiles = undefined,
        forbidTags = [],
        forbidAttr = [],
        debugScriptsAndStyles = false,
        addTags,
        addAttr,
    }: SanitizeHTMLParams = {},
): string => {
    if (!dirty) {
        return '';
    }
    return DOMPurify.sanitize(dirty, {
        ALLOWED_TAGS: allowedTags,
        ALLOWED_ATTR: allowedAttr,
        USE_PROFILES: useProfiles,
        FORBID_TAGS: forbidTags,
        FORBID_ATTR: forbidAttr,
        debugScriptsAndStyles,
        ADD_TAGS: addTags,
        ADD_ATTR: addAttr,
    } as Config & CustomParams) as string;
};

/**
 * if `debugScriptsAndStyles` is enabled in the config:
 * Wraps `<script>` and `<style>` elements in `<pre><code>` to disable them but still show them.
 */
DOMPurify.addHook(
    'beforeSanitizeElements',
    function debugScriptsAndStylesHook(node, data, config: Config & CustomParams) {
        if (!config.debugScriptsAndStyles || !(node instanceof Element)) {
            return;
        }
        if (node.parentNode && node.tagName && (node.tagName === 'SCRIPT' || node.tagName === 'STYLE')) {
            const pre = document.createElement('pre');
            const code = document.createElement('code');
            code.classList.add('language-html');
            code.textContent = node.outerHTML;
            pre.appendChild(code);
            node.parentNode.insertBefore(pre, node);
            node.remove();
        }
    },
);
