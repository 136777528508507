import React from 'react';

import { Thumbnail, ThumbnailProps } from '@lumapps/search-results/components/Thumbnail/Thumbnail';

interface CustomizationSearchThumbnail extends ThumbnailProps {
    iconPath?: string;
}

/**
 * Component that wraps the ThumbnailWithIcon component so that we can determine wether a dynamic icon is passed or not.
 * That allows the component to both be used with a dynamic icon if the `icon` prop is used, which the prop recommended for customization.
 * But it allows it to also be used using a `path` which could happen if the props retrieved in the customization context contains one.
 * @param props - CustomizationThumbnailWithIconProps
 */
export const CustomizationSearchThumbnail: React.FC<CustomizationSearchThumbnail> = ({ iconPath, icon, ...props }) => {
    return <Thumbnail {...props} icon={icon || iconPath} isDynamicIcon={Boolean(icon)} />;
};

CustomizationSearchThumbnail.displayName = 'CustomizationSearchThumbnail';
