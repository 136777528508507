/* istanbul ignore file */
// Ignoring coverage for this file since this will be reworked in Q2 2024
import { get } from '@lumapps/constants';
import { getDifferenceInDays } from '@lumapps/utils/date/getDatesDifference';

const constants = get();

export interface UseWrongDeploymentWarningProps {
    isEnabled?: boolean;
    buildTime?: Date;
    baseDate?: Date;
}

const FRONTEND_VERSION_THRESHOLDS: Record<string, number> = {
    WARNING: 5,
    ERROR: 10,
};

/**
 * Displays a notification if the currently deployed frontend version is not the correct one.
 *
 * @param UseWrongDeploymentWarningProps
 */
export const useWrongDeploymentWarning = ({
    isEnabled = constants.isSandbox && !constants.isDev,
    buildTime = new Date(constants.buildTime),
    baseDate = new Date(),
}: UseWrongDeploymentWarningProps) => {
    let content: string | null = null;

    if (isEnabled) {
        const diffDays = getDifferenceInDays(buildTime, baseDate);

        /**
         * If the current version is older than the warning threshold, display a warning that goes away
         * in one minute.
         */
        if (diffDays >= FRONTEND_VERSION_THRESHOLDS.WARNING && diffDays < FRONTEND_VERSION_THRESHOLDS.ERROR) {
            content = `Frontend version dates from more than ${FRONTEND_VERSION_THRESHOLDS.WARNING} days ago`;
        }

        /**
         * If the current version is older than the error threshold, display a warning that goes away
         * in one hour (practically never).
         */
        if (diffDays >= FRONTEND_VERSION_THRESHOLDS.ERROR) {
            content = `Frontend version dates from more than ${FRONTEND_VERSION_THRESHOLDS.ERROR} days ago`;
        }
    }

    return content;
};
