import React from 'react';

import { LoginPage } from '@lumapps/login/components/LoginPage';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { SIGN_IN } from '@lumapps/sign-in/keys';
import { useTranslate } from '@lumapps/translations';

import { CheckEmailsPage } from '../CheckEmailsPage';

export const CheckEmailsStep: React.FC<StepProps> = ({ backButton, ...props }) => {
    const { translateKey } = useTranslate();
    return (
        <LoginPage title={translateKey(SIGN_IN.CHECK_YOUR_EMAILS)} before={backButton} displayPoweredBy={false}>
            <CheckEmailsPage {...props} />
        </LoginPage>
    );
};
