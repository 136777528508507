import { get } from '@lumapps/constants';

import { Route } from '../types';
import { createUrl, createRelativeUrl, getParameterByName, addQueryParamsToUrl } from '../utils';
import { resetPageFocusAndScroll } from '../utils/resetPageFocusAndScroll';

const { applicationId, useDynamicOverrides } = get();

export const routerApi = {
    redirect: (
        route: Route,
        history?: any,
        options: { openInNewTab?: boolean; replace?: boolean; keepFocus?: boolean } = {},
    ) => {
        const { openInNewTab, replace, keepFocus } = options;
        const relativeUrl = createRelativeUrl(route);
        const url = createUrl(route, relativeUrl, route.instanceSlug);
        const override = getParameterByName('override');
        let relativeUrlWithParams = relativeUrl;

        if (useDynamicOverrides && override) {
            relativeUrlWithParams = addQueryParamsToUrl(relativeUrl, { override });
        }

        /**
         * For now, on the storybook side, we will show an alert that lets the developer know that a redirect will be triggered
         */
        if (window.ALERT_RATHER_THAN_REDIRECT) {
            /* eslint-disable-next-line */
            alert(`redirect to ${relativeUrlWithParams}${openInNewTab ? ' in new tab' : ''}`);
        } else if (openInNewTab) {
            // Open in new tab.
            window.open(url, undefined, 'noopener noreferrer');
        } else if (history && route.appId === applicationId && !route.instanceSlug) {
            // Unless specified otherwise, restore page focus on the body
            if (!keepFocus) {
                resetPageFocusAndScroll();
            }
            // Router redirect.
            if (replace) {
                // Replace will not keep history of the redirection
                history.replace(relativeUrlWithParams, route.state);
            } else {
                history.push(relativeUrlWithParams, route.state);
            }
        } else {
            // Hard redirect.
            // Route not migrated OR on another instance.
            window.location.href = url;
        }
    },
};
