import find from 'lodash/find';
import pick from 'lodash/pick';

import { get } from '@lumapps/constants';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import { isReportFeatureEnabled } from '@lumapps/reports/ducks/selectors';
import { getCookie } from '@lumapps/utils/cookies/cookie_utils';

import { TAB_DISPLAY_ROLES, SOCIAL_FEATURE_TOKEN, HIDE_USER_EMAIL } from '../constants';
import { User, UserAccountType } from '../types';
// eslint-disable-next-line import/no-cycle
import { getUserFullName } from '../utils/getUserFullName';
import { getAvatarUrl } from '../utils/getUserProfilePicture';

const constants = get();

const internalUserSliceSelector = (state: BaseStore) => state.user || {};

export const userSliceSelector = createSelector(internalUserSliceSelector, (userState) => userState);

export const connectedUserSelector = createSelector(userSliceSelector, (user): User | undefined => user?.connectedUser);

export const userIdSelector = createSelector(connectedUserSelector, (user) => user?.id);

export const isGodSelector = createSelector(connectedUserSelector, (user: User | undefined) => Boolean(user?.isGod));

export const wasUserInitialized = createSelector(userSliceSelector, (userState) => userState.initialized);

export const isDesigner = createSelector(connectedUserSelector, (user: User | undefined) => Boolean(user?.isDesigner));

export const isGuest = createSelector(connectedUserSelector, (user: User | undefined) => Boolean(user?.isGuest));

export const isConnected = createSelector(userIdSelector, isGuest, (userId, isAGuest: boolean) => {
    return Boolean(userId) && !isAGuest;
});

export const getEmail = createSelector(connectedUserSelector, (user) => user?.email);

export const accountTypeSelector = createSelector(connectedUserSelector, (user) => user?.accountType);

export const isExternalAccount = createSelector(
    accountTypeSelector,
    (accountType) => accountType === UserAccountType.EXTERNAL,
);

export const isGoogleAccount = createSelector(
    accountTypeSelector,
    (accountType) => accountType === UserAccountType.GOOGLE,
);

export const isMicrosoftAccount = createSelector(
    accountTypeSelector,
    (accountType) => accountType === UserAccountType.MICROSOFT,
);

export const apiProfileSelector = createSelector(connectedUserSelector, (user) => user?.apiProfile);

export const getSubscriptions = createSelector(connectedUserSelector, (user) => (user ? user.subscriptions : []));

export const userRolesSelector = createSelector(isGodSelector, (isGod) => ({
    [TAB_DISPLAY_ROLES.ROOT]: isGod,
}));

/**
 * Returns the URL to be used for the current logged in user's avatar.
 */
export const getPictureUrl = createSelector([customerIdSelector, userIdSelector], (customerId, userId) => {
    return getAvatarUrl(userId, customerId);
});

export const getFullName = createSelector(connectedUserSelector, (user) => getUserFullName(user, constants.userLang));

export const getToken = createSelector(connectedUserSelector, (user) => user?.token);

export const getPrimaryOrganization = createSelector(apiProfileSelector, (apiProfile) => {
    if (!apiProfile) {
        return null;
    }

    return find(apiProfile.organizations, (org) => org.primary);
});

export const connectedUserSettingsSelector = createSelector(
    connectedUserSelector,
    (connectedUser) => connectedUser?.settings,
);

export const hasAcceptedCookiesSelector = createSelector(
    isFeatureEnabled('gdpr-banner-widget'),
    (hasCookieBannerEnabled) => {
        if (
            (hasCookieBannerEnabled && getCookie('hasAcceptedCookies') === 'true') ||
            (!hasCookieBannerEnabled && getCookie('hasAcceptedCookies') !== 'false')
        ) {
            return true;
        }

        return false;
    },
);

export const hasUserProfile = isFeatureEnabled(SOCIAL_FEATURE_TOKEN);
export const isSocialFeatureEnabled = isFeatureEnabled(SOCIAL_FEATURE_TOKEN);

/** Returns true if the feature flag for hiding email addresses is enabled */
export const isHideEmailEnabled = isFeatureEnabled(HIDE_USER_EMAIL);

export const isReportAccessAvailable = createSelector(
    isReportFeatureEnabled,
    isInstanceSuperAdmin,
    (isReportEnabled, isAdmin) => isReportEnabled && isAdmin,
);

export const userListSelector = createSelector(
    userSliceSelector,
    (userState) => userState.userList?.ids?.map((id: string) => userState.entities[id]) || [],
);

export const userListStateSelector = createSelector(userSliceSelector, (userState) => userState.userList || {});

export const isAdministratorsAllowed = isSuperAdmin;

export const hasUserAcceptedTermsAndConditions = createSelector(
    connectedUserSelector,
    (user: User | undefined) => user?.settings?.hasAcceptedTerms,
);

export const getConnectedUserSummary = createSelector(connectedUserSelector, isInstanceSuperAdmin, (user, isAdmin) => {
    if (user) {
        const {
            lastName,
            firstName,
            langs,
            fullName,
            lang,
            id,
            isSuperAdmin,
            instancesSuperAdmin,
            profilePictureUrl,
            apiProfile,
        } = user;

        const apiProfileSubset = pick(apiProfile, [
            'addresses',
            'customSchemas',
            'emails',
            'phones',
            'primaryEmail',
            'externalIds',
            'organizations',
        ]);

        return {
            firstName,
            lastName,
            langs,
            fullName,
            lang,
            id,
            isAdmin,
            isSuperAdmin,
            instancesSuperAdmin,
            profilePictureUrl,
            ...apiProfileSubset,
        };
    }
    return {};
});

export const getConnectedUserLanguage = createSelector(
    connectedUserSelector,
    (user: User | undefined) => user?.lang || user?.langs?.[0],
);

export const getConnectedUserLoginProvider = createSelector(
    connectedUserSelector,
    (user: User | undefined) => user?.loginProvider,
);

export const getConnectedMSUserSharepointUrl = createSelector(
    connectedUserSelector,
    isMicrosoftAccount,
    (user: User | undefined, isMicrosoftAccount: boolean): string | undefined => {
        const userLoginHint = user?.apiProfile?.mail;

        if (!isMicrosoftAccount || !userLoginHint) {
            return undefined;
        }

        const sharePointDomain = userLoginHint.match(/@(\w*)\.onmicrosoft\.com$/i);

        /**
         * As a sharepoint home page url includes a subdomain, we need to make "by hand" the url
         * We construct the url using the subdomain we get with the user mail and his email as login_hint
         */
        if (sharePointDomain && sharePointDomain[1]) {
            return `https://${sharePointDomain[1]}.sharepoint.com/_layouts/15/sharepoint.aspx?&login_hint=${userLoginHint}`;
        }

        return undefined;
    },
);
